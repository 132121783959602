import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { Label } from "./styles";
import { CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { selectedCompany } from "src/store/company/companySlice";
import { useSelector } from "react-redux";
import DownloadCSV from "./DownloadCSV";
import { useAddBulkAppointmentsApi } from "src/api/appointments";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type DownloadCsvModalType = {
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: React.Dispatch<React.SetStateAction<void>>;
  refreshAppointments: () => void;
};

//const customFieldsLimit = 10000;

export default function ImportAppointments({
  error,
  setError,
  handleClose,
  refreshAppointments,
}: DownloadCsvModalType) {
  const selectedCompanyId = useSelector(selectedCompany);
  const [file, setFile] = useState<File | null>(null);
  const uploadCsvApi = useAddBulkAppointmentsApi();
  const [isAppointmentsUploading, setIsAppointmentsUploading] = useState(false);

  const formatHeaders = () => {
    const fields = "name,type,start date,end date,description,location,notification,phone number,confirmed";
    return fields.replace(/,\s*$/, "");
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const file = event.target?.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const addCsvAppointments = async () => {
    const formData = new FormData();
    file && formData.append("file", file);
    if (formData && selectedCompanyId) {
      setIsAppointmentsUploading(true);
      uploadCsvApi(formData, selectedCompanyId)
        .then(() => {
          setIsAppointmentsUploading(false)
          handleClose();
          refreshAppointments();
        })
        .catch((e) => {
          if (e.error === "validation") {
            setError(true);
          }
        })
        .finally(() => {
          setIsAppointmentsUploading(false);
          handleClose();
        });
    }
  };

  return (
    <Fragment>
      <span style={{ marginBottom: "20px" }}>
        {"Click "}
        <DownloadCSV data={formatHeaders()} fileName="Appointments-CSV-example" />
        {` to download the template for importing appointments.`}
      </span>
      <Button
        component="label"
        style={{ width: "250px", marginBottom: '20px' }}
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
      >
        Upload file
        <VisuallyHiddenInput
          type="file"
          onChange={handleFileUpload}
          accept=".csv"
        />
      </Button>
      {file?.name && <Label style={{marginTop: '-10px', marginBottom: '10px'}}>{`File: ${file?.name}`}</Label>}
      {error && (
        <FormLabel
          error={true}
          style={{ marginTop: "10px", textAlign: "center" }}
        >
          {
            "Your list of appoinments were unable to be uploaded. Please check the file for issues or try reducing the number of appoinments included in your upload."
          }
        </FormLabel>
      )}
      <Button
        variant="contained"
        style={{
          width: "150px",
          textAlign: "center",
          alignSelf: "flex-end",
        }}
        onClick={addCsvAppointments}
        disabled={!file || isAppointmentsUploading}
      >
        {isAppointmentsUploading ? <CircularProgress size={25} /> : "Import"}
      </Button>
    </Fragment>
  );
}
