import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ContentContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  addAwayCampaignValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { Campaign, DefaultCustomField, MessageTemplate, MessageType } from "src/constants/types";
import * as Yup from "yup";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import TemplateText from "src/pages/Campaigns/TemplateText/TemplateText";
import {
  CampaignType,
  ExternalType,
  ScheduledType,
} from "src/api/campaigns/types";
import {
  useCreateAwayMessageCampaignApi,
  useUpdateAwayMessageCampaignApi,
} from "src/api/campaigns";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    overflow: "visible",
  },
  "& .MuiDialogContent-root": {
    overflow: "visible",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateAwayMessageCampaignModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  awayMessageCampaign: Campaign | undefined;
  metadata: DefaultCustomField[] | undefined;
  messageTemplates: MessageTemplate[] | undefined;
  refreshAwayMessage: React.Dispatch<React.SetStateAction<void>>;
};

export default function CreateAwayMessageCampaignModal({
  show,
  setShowModal,
  awayMessageCampaign,
  metadata,
  messageTemplates,
  refreshAwayMessage,
}: CreateAwayMessageCampaignModalType) {
  const [, setErrors] = useState<ValidationErrors>({});
  const selectedCompanyId = useSelector(selectedCompany);
  const [campaignId, setCampaignId] = useState<string>(
    awayMessageCampaign ? awayMessageCampaign.id : ""
  );
  const [text, setText] = useState<string>(
    awayMessageCampaign ? awayMessageCampaign.scheduledMessages[0].text : ""
  );

  const createAwayMessageCampaign = useCreateAwayMessageCampaignApi();
  const updateAwayMessageCampaign = useUpdateAwayMessageCampaignApi();

  useEffect(() => {
    if (awayMessageCampaign) {
      setCampaignId(awayMessageCampaign.id);
      setText(awayMessageCampaign.scheduledMessages[0].text || '');
    }
  }, [awayMessageCampaign]);

  const handleClose = () => {
    setShowModal(false);
    setErrors({});
  };

  const convertHtmlToText = (html: string) => {
    const container = document.createElement("div");
    container.innerHTML = html
      .replaceAll("<br>", "\n")
      .replaceAll("<br/>", "\n");
    const customFields = container.querySelectorAll("span.mention");
    customFields.forEach((customField) => {
      customField.replaceWith(
        "{{" + customField.getAttribute("data-id") + "}}"
      );
    });
    const result = container.innerText;
    return result;
  };

  const handleAwayMessageCampaignSave = async () => {
    const isValid = validate(text);

    if (isValid) {
      const params = {
        campaignType: "away_message" as CampaignType,
        externalType: "custom" as ExternalType,
        name: "Away Message Campaign",
        scheduledMessages: [
          {
            text,
            type: "sms" as MessageType,
            subject: "",
            links: [],
            position: 1,
            scheduledType: "immediate" as ScheduledType,
          },
        ],
      };
      if (campaignId) {
        selectedCompanyId &&
          updateAwayMessageCampaign(params, campaignId, selectedCompanyId)
            .then(() => {
              refreshAwayMessage();
              handleClose();
            })
            .catch(({ error }) => {
              if (error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
            });
      } else {
        selectedCompanyId &&
          createAwayMessageCampaign(params, selectedCompanyId)
            .then(() => {
              refreshAwayMessage();
              handleClose();
            })
            .catch(({ error }) => {
              if (error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
            });
      }
    }
  };

  const isValidForm = (text: string) => {
    try {
      addAwayCampaignValidationSchema.validateSync(
        { text },
        {
          abortEarly: false,
        }
      );
      return true;
    } catch {
      return false;
    }
  };

  const validate = (text: string) => {
    try {
      addAwayCampaignValidationSchema.validateSync(
        { text },
        {
          abortEarly: false,
        }
      );
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title1"
        open={show}
        fullWidth={true}
        PaperProps={{ overflowY: "visible" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {campaignId ? "Update " : "Create "}
          {"Away Message Campaign"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            <Stack direction="column">
              <Box sx={{ position: "relative", marginTop: "10px" }}>
                <TemplateText
                  showConfirmationText={false}
                  confirmationText=""
                  setFocus={true}
                  customFields={metadata!}
                  messageTemplates={messageTemplates}
                  text={text}
                  updateText={(newText) => {
                    setText(convertHtmlToText(newText ?? ''));
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="end">
              <Button
                size="small"
                variant="outlined"
                style={{ width: "150px", marginRight: '10px', marginTop: "20px" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                disabled={!isValidForm(text)}
                variant="contained"
                style={{ width: "150px", marginTop: "20px" }}
                onClick={handleAwayMessageCampaignSave}
              >
                {campaignId ? "Save" : "Create"}
              </Button>
            </Stack>
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
    </Fragment>
  );
}
