import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  FormGroup,
} from "@mui/material";
import FileUploaderInput from "src/components/FileUploaderInput/view";
import { Message, MessageType } from "../types";
import dayjs from "dayjs";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

import {
  DefaultCustomField,
  FileLinkWithMimeType,
  MessageTemplate,
} from "src/constants/types";

import Divider from "@mui/material/Divider";
import TemplateText from "../../TemplateText/TemplateText";

export type MessageProps = {
  metadata: DefaultCustomField[] | undefined;
  messageTemplates: MessageTemplate[] | undefined;
  message: Message;
  updateMessage: React.Dispatch<React.SetStateAction<Message>>;
  disabled?: boolean;
};

export default function MessageForm({
  message,
  updateMessage,
  messageTemplates,
  metadata,
  disabled,
}: MessageProps) {
  const [messageValues, setMessageValues] = useState<Message>({
    ...message,
    isCaptureContact: message.dataCapture ? true : false,
  });
  const [delayPeriod, setDelayPeriod] = useState<string>("d");
  const [delayValue, setDelayValue] = useState<string>(
    message.scheduledType === "delay" && message.delay
      ? message.delay.slice(0, -1)
      : ""
  );
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (messageValues.scheduledType === "delay" && messageValues.delay) {
      setDelayPeriod(messageValues.delay[messageValues.delay?.length - 1]);
    }

    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (isLoaded) {
      updateMessage({ ...messageValues });
    }
  }, [messageValues]);

  const handleScheduleTypeChange = (
    e: SelectChangeEvent<"delay" | "reply" | "contact_added">
  ) => {
    const { value } = e.target;
    if (value === "time") {
      setDelayValue("");
      setDelayPeriod("m");
      setMessageValues({
        ...messageValues,
        scheduledType: value,
        delay: null,
      });
    }
    if (value === "delay") {
      setMessageValues({
        ...messageValues,
        scheduledType: value,
        time: null,
      });
    }
    if (value === "reply" || value === "contact_added") {
      setDelayValue("");
      setDelayPeriod("d");
      setMessageValues({
        ...messageValues,
        scheduledType: value,
        time: null,
        delay: null,
      });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "scheduledType") {
      if (value === "time") {
        setDelayValue("");
        setDelayPeriod("d");
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          delay: null,
        });
      }
      if (value === "delay") {
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
        });
      }
      if (value === "reply" || value === "contact_added") {
        setDelayValue("");
        setDelayPeriod("m");
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
          delay: null,
        });
      }
    } else {
      setMessageValues({ ...messageValues, [name]: value });
    }
  };

  const handleMessageTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    messageType: string
  ) => {
    if (messageType) {
      setMessageValues({ ...messageValues, type: messageType as MessageType });
    }
  };

  const handleLinksUpdate = (links: FileLinkWithMimeType[]) => {
    setMessageValues({
      ...messageValues,
      links: links.map((x) => x.url),
      linksWithMimeType: links,
    });
    updateMessage({
      ...messageValues,
      links: links.map((x) => x.url),
      linksWithMimeType: links,
    });
  };

  const handleSchedulePeriodChange = ({
    target,
  }: SelectChangeEvent<string>) => {
    setDelayPeriod(target.value);
    setMessageValues({
      ...messageValues,
      time: null,
      delay: `${delayValue}${target.value}`,
    });
    updateMessage({
      ...messageValues,
      time: null,
      delay: `${delayValue}${target.value}`,
    });
  };

  const handleDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDelayValue(value);
    setMessageValues({
      ...messageValues,
      time: null,
      [name]: `${value}${delayPeriod}`,
    });
  };

  const handleTimeSave = async (date: dayjs.Dayjs | null) => {
    setMessageValues({
      ...messageValues,
      delay: null,
      time: dayjs(date).toDate().toISOString(),
    });
  };

  const confirmationReminderText =
    "<br/>Please confirm your appointment by replying Y or Yes to this message. To make changes please call.";

  return (
    <Fragment>
      <FormControl
        fullWidth
        style={{
          flexDirection: "row",
          alignItems: "center",
          margin: "0 0",
          marginBottom: "10px",
        }}
      >
        <ToggleButtonGroup
          disabled={disabled}
          size="small"
          color="primary"
          value={message.type}
          exclusive
          onChange={handleMessageTypeChange}
          aria-label="messageType"
        >
          <ToggleButton value="sms">SMS</ToggleButton>
          <ToggleButton value="mms">MMS</ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      {message.type === "mms" && (
        <>
          <FileUploaderInput
            disabled={!!disabled}
            key={message.id}
            links={message.linksWithMimeType as FileLinkWithMimeType[]}
            updateLinks={handleLinksUpdate}
          />
        </>
      )}
      <FormGroup sx={{ marginTop: "-10px" }}>
        <FormControlLabel
          sx={{ marginRight: 0, marginBottom: "5px" }}
          control={
            <Checkbox
              disabled={disabled}
              name="attachCalendar"
              checked={!!messageValues.attachCalendar}
              onChange={(e) => {
                setMessageValues({
                  ...messageValues,
                  attachCalendar: e.target.checked,
                });
              }}
            />
          }
          label="Attach calendar invite"
        />
      </FormGroup>
      {message.type === "mms" && (
        <>
          <TextField
            disabled={disabled}
            fullWidth
            id="outlined-basic"
            label="Subject (optional)"
            name="subject"
            variant="outlined"
            value={message.subject}
            onChange={handleChange}
          />
          <FormHelperText id="outlined-weight-helper-text">
            Different devices and applications display the subject of an MMS in
            different ways. Avoid including critical information in the subject.
          </FormHelperText>
        </>
      )}
      <Stack direction="column">
        <Box
          sx={{
            position: "relative",
            pointerEvents: disabled ? "none" : "all",
            marginBottom: "5px",
          }}
        >
          {metadata && (
            <>
              <TemplateText
                customFields={metadata!}
                messageTemplates={messageTemplates}
                text={messageValues.text}
                setFocus={false}
                updateText={(newText) =>
                  setMessageValues({ ...messageValues, text: newText })
                }
                showConfirmationText={!!messageValues.dataCapture}
                confirmationText={confirmationReminderText}
              />
            </>
          )}
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{ marginTop: "-10px" }}>
        <FormGroup>
          <FormControlLabel
            sx={{ marginRight: 0 }}
            control={
              <Checkbox
                disabled={disabled}
                name="isCaptureContact"
                checked={!!messageValues.dataCapture}
                onChange={(e) => {
                  setMessageValues({
                    ...messageValues,
                    dataCapture: e.target.checked
                      ? "appointment.confirmation_status"
                      : undefined,
                    text:
                      e.target.checked &&
                      messageValues.text.endsWith(
                        "To make changes please call."
                      )
                        ? messageValues.text + confirmationReminderText
                        : messageValues.text.replace(
                            confirmationReminderText,
                            ""
                          ),
                  });
                }}
              />
            }
            label="Include appointment confirmation request"
          />
        </FormGroup>
      </Stack>
      <>
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          height="30px"
          sx={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <FormControl
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: "13px",
            }}
          >
            <FormLabel style={{ color: "#000" }}>Schedule:</FormLabel>
            <Select
              disabled={disabled}
              sx={{ marginLeft: "10px", marginRight: "10px", height: "35px" }}
              MenuProps={{ disableScrollLock: true }}
              labelId="demo-simple-select-label"
              size="small"
              value={messageValues.scheduledType}
              onChange={handleScheduleTypeChange}
              name="scheduledType"
            >
              <MenuItem
                disabled={message.position > 1}
                value={"contact_added"}
                key={"contact_added"}
              >
                Contact Added to Appointment
              </MenuItem>
              <MenuItem value={"delay"} key={"delay"}>
                Reminder
              </MenuItem>
              <MenuItem
                disabled={message.position === 1}
                value={"reply"}
                key={"reply"}
              >
                After Confirmation
              </MenuItem>
            </Select>
          </FormControl>
          {message.scheduledType === "delay" && (
            <Box
              display={"flex"}
              sx={{
                flexDirection: "column",
                marginTop: "13px",
              }}
            >
              <Box display={"flex"} alignItems="center">
                <TextField
                  disabled={disabled}
                  sx={{
                    marginRight: "10px",
                    "& .MuiOutlinedInput-input": {
                      height: "35px",
                      paddingTop: 0,
                      paddingBottom: 0,
                      width: "80px",
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Period"
                  name="delay"
                  size="small"
                  type="number"
                  inputProps={{ min: 1 }}
                  variant="outlined"
                  value={delayValue.replace("-", "")}
                  onChange={handleDelayChange}
                />
                <Select
                  disabled={disabled}
                  sx={{ height: "35px" }}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={delayPeriod}
                  onChange={handleSchedulePeriodChange}
                >
                  <MenuItem value={"m"} key={"minutes"}>
                    Minutes
                  </MenuItem>
                  <MenuItem value={"h"} key={"hours"}>
                    Hours
                  </MenuItem>
                  <MenuItem value={"d"} key={"days"}>
                    Days
                  </MenuItem>
                </Select>
                <FormLabel style={{ color: "#000", marginLeft: "10px" }}>
                  Before Appointment
                </FormLabel>
              </Box>
            </Box>
          )}
          {message.scheduledType === "time" && (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  sx={{ marginTop: "5px" }}
                  components={["DateTimePicker"]}
                >
                  <DateTimePicker
                    slotProps={{ textField: { size: "small" } }}
                    label="Date and Time Scheduled"
                    disablePast
                    onChange={(value) => handleTimeSave(value)}
                    defaultValue={message.time ? dayjs(message.time) : null}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </>
          )}
        </Stack>
      </>
    </Fragment>
  );
}
