import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import FileUploaderInput from "src/components/FileUploaderInput/view";
import { Message, MessageType } from "../types";

import {
  DefaultCustomField,
  FileLinkWithMimeType,
  MessageTemplate,
} from "src/constants/types";

import Divider from "@mui/material/Divider";
import TemplateText from "../../TemplateText/TemplateText";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";

export type MessageProps = {
  metadata: DefaultCustomField[] | undefined;
  messageTemplates: MessageTemplate[] | undefined;
  message: Message;
  updateMessage: React.Dispatch<React.SetStateAction<Message>>;
  disabled?: boolean;
};

export default function MessageForm({
  message,
  updateMessage,
  messageTemplates,
  metadata,
  disabled,
}: MessageProps) {
  const minutesInDay = 1440;

  const [messageValues, setMessageValues] = useState<Message>({
    ...message,
    isCaptureContact: message.dataCapture ? true : false,
  });

  const getDelayInMinutes = (delay: string) => {
    const value = delay.slice(0, -1);
    const delayInMinutes = Math.abs(Number(value));
    return delayInMinutes;
  };

  const isDelayBefore = (delay: string) => {
    return delay.startsWith("-");
  };

  const getDelayDays = (delay: string | undefined): number => {
    if (!delay) {
      return 0;
    }
    const delayInMinutes = getDelayInMinutes(delay);
    const isBefore = isDelayBefore(delay);

    const days = isBefore
      ? Math.ceil(Math.abs(delayInMinutes) / minutesInDay)
      : Math.floor(Math.abs(delayInMinutes) / minutesInDay);

    return days;
  };

  const [pastOrFuture, setPastOrFuture] = useState<string>(
    !message.delay || getDelayDays(message.delay) === 0 ? "before" : isDelayBefore(message.delay) ? "before" : "after"
  );

 

  const roundToNearest5 = (x: number) => Math.round(x / 5) * 5;

  const getDelayTime = (delay: string | undefined): number => {
    if (!delay) {
      return 540;
    }
    const delayInMinutes = getDelayInMinutes(delay);
    const isBefore = isDelayBefore(delay);

    const time = !isBefore
      ? roundToNearest5(delayInMinutes % minutesInDay)
      : roundToNearest5(
          delayInMinutes > minutesInDay
            ? delayInMinutes % minutesInDay
            : minutesInDay % delayInMinutes
        );

    const result =
      delayInMinutes < minutesInDay
        ? time
        : isBefore
        ? minutesInDay - time
        : time;

    return result;
  };

  const [delayDays, setDelayDays] = useState<number | undefined>(
    message.delay ? getDelayDays(message.delay) : 0
  );
  const [delayTime, setDelayTime] = useState<number>(
    message.delay ? getDelayTime(message.delay) : 540
  );

  const getDelayValue = (
    pastOrFuture: string,
    delayDays: number,
    delayTime: number
  ): string => {
    const value =
      pastOrFuture === "after"
        ? delayDays * minutesInDay + Math.abs(delayTime)
        : -delayDays * minutesInDay + Math.abs(delayTime);

    return `${pastOrFuture === "before" && delayDays > 0 ? "-" : ""}${Math.abs(
      value
    )}m`;
  };

  useEffect(() => {
    updateMessage({ ...messageValues });
  }, [messageValues]);

  useEffect(() => {
    updateMessage({ ...messageValues });
  }, [messageValues]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name === "scheduledType") {
      if (value === "delay") {
        setMessageValues({
          ...messageValues,
          scheduledType: value,
          time: null,
        });
      }
    } else {
      setMessageValues({ ...messageValues, [name]: value });
    }
  };

  const handleMessageTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    messageType: string
  ) => {
    if (messageType) {
      setMessageValues({ ...messageValues, type: messageType as MessageType });
    }
  };

  const handleLinksUpdate = (links: FileLinkWithMimeType[]) => {
    setMessageValues({
      ...messageValues,
      links: links.map((x) => x.url),
      linksWithMimeType: links,
    });
    updateMessage({
      ...messageValues,
      links: links.map((x) => x.url),
      linksWithMimeType: links,
    });
  };

  const handlePastOfFutureChange = ({ target }: SelectChangeEvent<string>) => {
    setPastOrFuture(target.value);
    setMessageValues({
      ...messageValues,
      time: null,
      delay: getDelayValue(target.value, delayDays ?? 0, delayTime),
    });
  };

  const handleTimeChange = async (time: dayjs.Dayjs | null) => {
    if (time) {
      const minutesInHour = 60;
      const hours = time.get("h");
      const minutes = time.get("m");
      const timeInMinutes = hours * minutesInHour + minutes;
      setDelayTime(timeInMinutes);
      setMessageValues({
        ...messageValues,
        time: null,
        delay: getDelayValue(pastOrFuture, delayDays ?? 0, timeInMinutes),
      });
    }
  };

  const handleDelayDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const days = Number(value);

    setDelayDays(value === "" ? undefined : days);

    setMessageValues({
      ...messageValues,
      time: null,
      [name]: !value
        ? "NaN"
        : getDelayValue(pastOrFuture, Number(value), delayTime),
    });
  };

  const getScheduledTime = (delay: string | undefined) => {
    if (!delay) {
      return "";
    }

    const delayValue = getDelayInMinutes(delay);

    if (!delayValue) {
      return "";
    }

    const time = delayTime
      ? dayjs().startOf("d").add(Math.abs(delayTime), "m")
      : dayjs().startOf("d").add(Math.abs(540), "m");

    return `Scheduled: ${Math.abs(delayDays ?? 0)} day(s) ${
      isDelayBefore(delay) || Math.abs(delayDays ?? 0) === 0 ? "before" : "after"
    } Annual Campaign at ${time.format("hh:mm A")}`;
  };

  return (
    <Fragment>
      <div
        style={{
          marginTop: "-15px",
          marginBottom: "10px",
          color: "#b2b2b2",
          fontSize: "13px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ height: "16px", color: "#ccc" }}>
          {messageValues.scheduledType === "delay" &&
            messageValues.delay &&
            messageValues.delay.length > 1 && (
              <>{getScheduledTime(messageValues.delay)}</>
            )}
        </div>
      </div>
      <FormControl
        fullWidth
        style={{
          flexDirection: "row",
          alignItems: "center",
          margin: "0 0",
          marginBottom: "10px",
        }}
      >
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={message.type}
          exclusive
          onChange={handleMessageTypeChange}
          aria-label="messageType"
        >
          <ToggleButton value="sms">SMS</ToggleButton>
          <ToggleButton value="mms">MMS</ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      {message.type === "mms" && (
        <FileUploaderInput
          disabled={!!disabled}
          key={message.id}
          links={message.linksWithMimeType as FileLinkWithMimeType[]}
          updateLinks={handleLinksUpdate}
        />
      )}
      {message.type === "mms" && (
        <>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Subject (optional)"
            name="subject"
            variant="outlined"
            value={message.subject}
            onChange={handleChange}
            style={{ marginTop: "20px" }}
          />
          <FormHelperText id="outlined-weight-helper-text">
            Different devices and applications display the subject of an MMS in
            different ways. Avoid including critical information in the subject.
          </FormHelperText>
        </>
      )}
      <Stack direction="column">
        <Box sx={{ position: "relative", marginBottom: "5px" }}>
          {metadata && (
            <>
              <TemplateText
                setFocus={true}
                showConfirmationText={false}
                confirmationText=""
                disabled={!!disabled}
                customFields={metadata!}
                messageTemplates={messageTemplates}
                text={messageValues.text}
                updateText={(newText) =>
                  setMessageValues({ ...messageValues, text: newText })
                }
              />
            </>
          )}
        </Box>
      </Stack>
      <>
        <Divider sx={{ marginTop: "20px" }} />
        <Stack
          direction="row"
          alignItems="center"
          height="30px"
          sx={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {message.scheduledType === "delay" && (
            <Box
              display={"flex"}
              sx={{
                flexDirection: "column",
                marginTop: "13px",
              }}
            >
              <Box display={"flex"}>
                <FormControl
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormLabel style={{ color: "#000" }}>Schedule:</FormLabel>
                </FormControl>
                <TextField
                  sx={{
                    width: "90px",
                    marginRight: "10px",
                    marginLeft: "10px",
                    "& .MuiOutlinedInput-input": {
                      height: "35px",
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Period"
                  name="delay"
                  size="small"
                  type="number"
                  inputProps={{ min: 0 }}
                  variant="outlined"
                  value={
                    (delayDays === 0 || delayDays) && !Number.isNaN(delayDays)
                      ? Math.abs(delayDays)
                      : ""
                  }
                  onChange={handleDelayDaysChange}
                />
                <FormControl
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormLabel style={{ color: "#000" }}>Days</FormLabel>
                </FormControl>
                <Select
                  sx={{ height: "35px", marginLeft: "10px" }}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="demo-simple-select-label"
                  size="small"
                  value={
                    Math.abs(delayDays ?? 0) === 0 ? "before" : pastOrFuture
                  }
                  onChange={handlePastOfFutureChange}
                >
                  <MenuItem value={"before"} key={"before"}>
                    Before
                  </MenuItem>
                  <MenuItem value={"after"} key={"after"}>
                    After
                  </MenuItem>
                </Select>
                <FormControl
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <FormLabel style={{ color: "#000" }}>at</FormLabel>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ marginTop: "-8px" }}
                    components={["DateTimePicker"]}
                  >
                    <TimePicker
                      sx={{
                        "& .MuiStack-root": { paddingTop: 0 },
                        "& .MuiOutlinedInput-root": {
                          height: "35px",
                          width: "150px",
                          marginRight: 0,
                          marginLeft: "10px",
                        },
                        overflow: "hidden",
                      }}
                      onChange={(value) => handleTimeChange(value)}
                      defaultValue={
                        delayTime
                          ? dayjs().startOf("d").add(Math.abs(delayTime), "m")
                          : dayjs().startOf("d").add(Math.abs(540), "m")
                      }
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
          )}
        </Stack>
      </>
    </Fragment>
  );
}
