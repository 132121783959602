export const APP_CONFIG = {
  local: [
    {
      WEB_URL: "localhost",
      API_BASE_URL: `https://api-dev.agencytext.com`,
    },
  ],
  dev: [
    {
      WEB_URL: "app-dev.agencytext.com",
      API_BASE_URL: `https://api-dev.agencytext.com`,
    },
    {
      WEB_URL: "app-dev.textpickle.com",
      API_BASE_URL: `https://api-dev.textpickle.com`,
    },
  ],
  qa: [
    {
      WEB_URL: "app-qa.agencytext.com",
      API_BASE_URL: `https://api-qa.agencytext.com`,
    },
    {
      WEB_URL: "app-qa.textpickle.com",
      API_BASE_URL: `https://api-qa.textpickle.com`,
    },
  ],
  app: [
    {
      WEB_URL: "app.agencytext.com",
      API_BASE_URL: `https://api.agencytext.com`,
    },
    {
      WEB_URL: "app.textpickle.com",
      API_BASE_URL: `https://api.textpickle.com`,
    },
  ],
};
