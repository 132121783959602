import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainWrapper from "src/components/MainWrapper/view";
import { selectProfile } from "src/store/profile/profileSlice";
import {
  Tabs,
  Tab,
  Box,
  styled,
  TabScrollButton,
} from "@mui/material";
import CompanyInfoTab from "./CompanyInfoTab";
import CompanySubscriptionTab from "./CompanySubscriptionTab";
import CompanyPhonesTab from "./CompanyPhonesTab";
import { useGetAdminCompanyApi } from "src/api/adminCompanies";
import { Link, useParams } from "react-router-dom";
import { AdminCompany } from "src/constants/types";
import { PageHeader } from "src/pages/Groups/styles";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";

const MyTabScrollButton = styled(TabScrollButton)({
  "&.Mui-disabled": {
    width: 0,
  },
  overflow: "hidden",
  transition: "width 0.1s",
  width: 28,
});

type TabPanelProps = {
  children?: React.ReactNode;
  name: string;
  value: string;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, name, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`simple-tabpanel-${name}`}
      aria-labelledby={`simple-tab-${name}`}
      {...other}
    >
      {value === name && <>{children}</>}
    </div>
  );
};

const getProps = (name: string) => {
  return {
    id: `simple-tab-${name}`,
    "aria-controls": `simple-tabpanel-${name}`,
  };
};


const EditCompany = () => {
  const [tab, setTab] = useState("info");
  const { profile } = useSelector(selectProfile);
  const [company, setCompany] = useState<AdminCompany>();
  const { companyId } = useParams();
  const getCompany = useGetAdminCompanyApi();
  useHandleSelectedCompany();

  const refreshCompany = () => {
    if (!companyId) {
      return;
    }

    getCompany(companyId).then((response) => {
      setCompany(response.data);
    })
  }

  useEffect(() => {
   refreshCompany();
  }, [companyId])

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  const formatPageHeader = (companyName: string) => {
    const style = { textDecoration: "none", color: "#254597" };
    return (
      <>
        <Link to="/admin/companies" style={style}>
          Companies
        </Link>{" "}
        / {companyName}
      </>
    );
  };

  const isBhiAdmin = profile?.type === "admin";

  if (!isBhiAdmin) {
    return null;
  }

  return (
    <MainWrapper>
      {profile && company && <Box sx={{ width: "100%" }}>
      <PageHeader>{formatPageHeader(company.name)}</PageHeader>
        <Tabs sx={{marginTop: '30px'}}
          value={tab}
          onChange={handleTabChange}
          aria-label="editCompany tab"
          ScrollButtonComponent={MyTabScrollButton}
          scrollButtons="auto"
        >
          <Tab label="Company Info" value='info' {...getProps('info')} />
          <Tab label="Subscription" value='subscription' {...getProps('subscription')} />
          <Tab label="Phones" value='phones' {...getProps('phones')} />
        </Tabs>
        <CustomTabPanel value={tab} name='info'>
          <CompanyInfoTab company={company} refreshCompany={refreshCompany} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} name='subscription'>
          <CompanySubscriptionTab company={company} />
        </CustomTabPanel>
        <CustomTabPanel value={tab} name='phones'>
          <CompanyPhonesTab company={company} />
        </CustomTabPanel>
      </Box>}
    </MainWrapper>
  );
};

export default EditCompany;
