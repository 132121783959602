import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  addAppointmentTypeValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { AppointmentType, User } from "src/constants/types";
import * as Yup from "yup";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { selectProfile } from "src/store/profile/profileSlice";
import {
  useCreateAppointmentTypeApi,
  useUpdateAppointmentTypeApi,
} from "src/api/appointmentTypes";
import { ColorPicker, IColor, useColor } from "react-color-palette";
import "react-color-palette/css";
import { getRandomHexColor } from "../helpers";
import { useGetUsersApi } from "src/api/users";
import { GetUsersParams } from "src/api/users/types";
import BannedWordsModal from "src/components/BannedWordsModal/view";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    overflow: "visible",
  },
  "& .MuiDialogContent-root": {
    overflow: "visible",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateAppointmentTypeModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialAppointmentType: AppointmentType;
  appointmentType: AppointmentType;
  refreshAppointmentTypes: () => void;
};

export default function CreateAppointmentTypeModal({
  show,
  setShowModal,
  initialAppointmentType,
  appointmentType,
  refreshAppointmentTypes,
}: CreateAppointmentTypeModalType) {
  const itemsPerPage = 10000;

  const [errors, setErrors] = useState<ValidationErrors>({});
  const selectedCompanyId = useSelector(selectedCompany);
  const createAppointmentType = useCreateAppointmentTypeApi();
  const updateAppointmentType = useUpdateAppointmentTypeApi();
  const getUsers = useGetUsersApi();
  const { profile } = useSelector(selectProfile);
  const [formValues, setFormValues] =
    useState<AppointmentType>(appointmentType);
  const [color, setColor] = useColor(formValues.color);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);
  const [companyUsers, setCompanyUsers] = useState<User[]>();
  const [assignedToId, setAssignedToId] = useState<string>(
    appointmentType.assignedTo?.id || ""
  );

  const [delayPeriod, setDelayPeriod] = useState<string>("m");
  const [delayValue, setDelayValue] = useState<string>();

  const [showbannedWordsModal, setShowBannedWordsModal] =
      useState<boolean>(false);

  const updateColor = (color: IColor) => {
    setColor(color);
    setFormValues({ ...formValues, color: color.hex });
  };

  const updateColorComplete = (color: IColor) => {
    setColor(color);
    setFormValues({ ...formValues, color: color.hex });
  };

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    setFormValues(appointmentType);
    setAssignedToId(appointmentType.assignedTo?.id || "");

    const reqQuery: GetUsersParams = {
      companyId: selectedCompanyId,
      limit: itemsPerPage,
      offset: 0,
    };
    getUsers(reqQuery)
      .then(({ data }) => {
        setCompanyUsers(data.items);
      })
      .catch((e) => {
        console.log(e);
      });
    if (appointmentType.notification) {
      setDelayValue(
        appointmentType.notification
          ? appointmentType.notification.slice(0, -1)
          : ""
      );
      setDelayPeriod(
        appointmentType.notification
          ? appointmentType.notification[
              appointmentType.notification.length - 1
            ]
          : "m"
      );
    }
  }, [appointmentType]);

  const handleClose = () => {
    setFormValues({ ...initialAppointmentType, color: getRandomHexColor() });
    setShowModal(false);
    setErrors({});
    setDelayPeriod("m");
    setDelayValue("");
    setAssignedToId("");
    setShowColorPicker(false);
  };

  const formatDelayNotification = (
    notification?: string
  ): string | undefined => {
    return notification
      ? notification.startsWith("-")
        ? notification
        : "-" + notification
      : undefined;
  };

  const handleAppointmentTypeSave = () => {
    const isValid = validate(formValues);
    if (isValid && selectedCompanyId) {
      if (formValues.id) {
        updateAppointmentType(
          formValues.name,
          formValues.color,
          formValues.id,
          selectedCompanyId,
          formValues.location || undefined,
          formValues.description || undefined,
          formatDelayNotification(formValues.notification),
          assignedToId || undefined
        ).then(() => {
          refreshAppointmentTypes();
          handleClose();
        }).catch((error) => {
          if (error.code === 422) {
            setShowBannedWordsModal(true);
          }
          console.log(error);
        });
      } else {
        createAppointmentType(
          formValues.name,
          formValues.color,
          selectedCompanyId,
          formValues.location || undefined,
          formValues.description || undefined,
          formatDelayNotification(formValues.notification),
          assignedToId || undefined
        )
          .then((response) => {
            setFormValues(response.data);
            handleClose();
            refreshAppointmentTypes();
          })
          .catch((error) => {
            if (error.error === "conflict") {
              setErrors({
                name: "Appointment Type with this name already exists",
              });
            }
            if (error.message && error.message.includes("duplicate key")) {
              setErrors({
                name: "Appointment Type with this name already exists",
              });
            }
            console.log(error);
            if (error.code === 422) {
              setShowBannedWordsModal(true);
            }
          });
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const isValidForm = (values: typeof formValues) => {
    try {
      addAppointmentTypeValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return true;
    } catch {
      return false;
    }
  };

  const handleDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDelayValue(value);
    setFormValues({
      ...formValues,
      notification: value ? `${value}${delayPeriod}` : undefined,
    });
  };

  const handleNotificationPeriodChange = ({
    target,
  }: SelectChangeEvent<string>) => {
    setDelayPeriod(target.value);
    setFormValues({
      ...formValues,
      notification: `${delayValue}${target.value}`,
    });
  };

  const handleAssignedToChange = ({ target }: SelectChangeEvent<string>) => {
    setAssignedToId(target.value);
  };

  const validate = (values: typeof formValues) => {
    try {
      addAppointmentTypeValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const formatAssignedToName = (contact: User): string => {
    if (contact.firstName || contact.lastName) {
      return `${contact.firstName || ""} ${contact.lastName || ""}`;
    }

    return contact.phoneNumber;
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title1"
        open={show}
        fullWidth={true}
        PaperProps={{ overflowY: "visible" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {profile?.type === "user"
            ? ""
            : formValues.id
            ? "Update "
            : "Create "}
          {"Appointment Type"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ paddingTop: 0 }}>
          <ContentContainer>
            <TextField
              autoComplete="off"
              sx={{ marginBottom: "15px" }}
              id="outlined-basic"
              size="small"
              label={"Appointment Type"}
              name="name"
              variant="outlined"
              disabled={profile?.role?.name === "company_user"}
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors?.name || ""}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              {showColorPicker && (
                <Box
                  sx={{ position: "absolute", top: "-115px" }}
                  onMouseLeave={() => {
                    setShowColorPicker(false);
                  }}
                >
                  <ColorPicker
                    onChangeComplete={updateColorComplete}
                    height={80}
                    hideInput={["hsv", "rgb"]}
                    color={color}
                    onChange={updateColor}
                  />
                </Box>
              )}
            </Box>
            <TextField
              size="small"
              autoComplete="off"
              sx={{ marginBottom: "15px" }}
              id="outlined-basic"
              label={"Color"}
              name="color"
              variant="outlined"
              onFocus={() => setShowColorPicker(true)}
              disabled={profile?.role?.name === "company_user"}
              value={formValues.color}
              onChange={() => {}}
              error={!!errors.color}
              helperText={errors?.color || ""}
            />
            <FormControl sx={{ height: "35px" }} size="small">
              <InputLabel id="assigned-to-label">Assignee</InputLabel>
              <Select
                MenuProps={{ disableScrollLock: true }}
                labelId="assigned-to-label"
                size="small"
                label="Assignee"
                value={assignedToId}
                onChange={handleAssignedToChange}
              >
                <MenuItem value={""} key={"unassign"}>
                  Unassigned
                </MenuItem>
                {companyUsers?.map((u) => (
                  <MenuItem key={u.id} value={u.id}>
                    {formatAssignedToName(u)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ marginBottom: "5px", marginTop: "20px" }}>
              Calendar Attachment Defaults
            </Box>
            <Typography
              fontStyle={"italic"}
              fontSize={12}
              sx={{ marginBottom: "10px" }}
            >
              The following fields are optional. They are used as default values
              for new appointments and the optional calendar invites sent to
              contacts as part of Appointment Reminder campaigns.
            </Typography>
            <TextField
              sx={{ marginBottom: "10px" }}
              autoComplete="off"
              id="outlined-basic"
              label={"Default Location"}
              size="small"
              name="location"
              variant="outlined"
              value={formValues.location}
              onChange={handleChange}
              error={!!errors.location}
              helperText={errors?.location || ""}
            />
            <Box
              display={"flex"}
              sx={{
                flexDirection: "column",
              }}
            >
              <Box display={"flex"}>
                <TextField
                  sx={{
                    marginRight: "10px",
                    width: "50%",
                    "& .MuiOutlinedInput-input": {
                      height: "35px",
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  }}
                  id="outlined-basic"
                  placeholder="Calendar Notification"
                  name="delay"
                  size="small"
                  type="number"
                  inputProps={{ min: 1 }}
                  variant="outlined"
                  value={delayValue?.replace("-", "")}
                  onChange={handleDelayChange}
                />
                <Select
                  sx={{ height: "35px", width: "50%" }}
                  MenuProps={{ disableScrollLock: true }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  size="small"
                  value={delayPeriod}
                  onChange={handleNotificationPeriodChange}
                >
                  <MenuItem value={"m"} key={"minutes"}>
                    Minutes
                  </MenuItem>
                  <MenuItem value={"h"} key={"hours"}>
                    Hours
                  </MenuItem>
                  <MenuItem value={"d"} key={"days"}>
                    Days
                  </MenuItem>
                </Select>
              </Box>
            </Box>
            <TextField
              sx={{ marginTop: "10px" }}
              autoComplete="off"
              size="small"
              id="outlined-basic"
              label={"Default Description"}
              multiline
              rows="3"
              name="description"
              variant="outlined"
              value={formValues.description}
              onChange={handleChange}
              error={!!errors.description}
              helperText={errors?.description || ""}
            />
            <Stack direction="row" justifyContent="end">
              {profile?.role?.name !== "company_user" && (
                <Button
                  size="small"
                  disabled={!isValidForm(formValues)}
                  variant="contained"
                  style={{ width: "150px", marginTop: "20px" }}
                  onClick={handleAppointmentTypeSave}
                >
                  {formValues.id ? "Save" : "Create"}
                </Button>
              )}
              {profile?.role?.name === "company_user" && (
                <Button
                  size="small"
                  variant="contained"
                  style={{ width: "150px", marginTop: "20px" }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              )}
            </Stack>
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
       <BannedWordsModal
              isOpen={showbannedWordsModal}
              onConfirm={() => setShowBannedWordsModal(false)}
            />
    </Fragment>
  );
}
