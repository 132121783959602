import MainWrapper from "src/components/MainWrapper/view";
import React, { useMemo, useState } from "react";
import { Tabs, Tab, Box, TabScrollButton, styled } from "@mui/material";
import UserManagment from "./Components/UserManagement/view";
import { useSelector } from "react-redux";
import Integrations from "./Components/Integrations/view";
import { isActionAllowed } from "src/helpers/permissions";
import { selectProfile } from "src/store/profile/profileSlice";
import CustomFields from "./Components/CustomFields/view";
import ApiKeys from "./Components/ApiKeys/view";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import MessageTemplates from "./Components/MessageTemplates/view";
import AppointmentTypes from "./Components/AppointmentTypes/view";


const MyTabScrollButton = styled(TabScrollButton)({
  '&.Mui-disabled': {
      width: 0,
  },
  overflow: 'hidden',
  transition: 'width 0.1s',
  width: 28,
});

type TabPanelProps = {
  children?: React.ReactNode;
  name: string;
  value: string;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, name, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`simple-tabpanel-${name}`}
      aria-labelledby={`simple-tab-${name}`}
      {...other}
    >
      {value === name && <>{children}</>}
    </div>
  );
};

const getProps = (name: string) => {
  return {
    id: `simple-tab-${name}`,
    "aria-controls": `simple-tabpanel-${name}`,
  };
};

const PersonalInformation = () => {
  const [tab, setTab] = useState('companies');
  const { profile } = useSelector(selectProfile);
  useHandleSelectedCompany();
  const profileRole = useMemo(
    () => (profile?.role ? profile.role.name : "bhi_admin"),
    [profile]
  );

  const handleTabChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  if (!profile) {
    return null;
  }

  return (
    <MainWrapper>
      <Box sx={{ width: "100%" }}>
        <Tabs
          ScrollButtonComponent={MyTabScrollButton}
          scrollButtons="auto"
          value={tab}
          variant="scrollable"
          onChange={handleTabChange}
          aria-label="personal information tab"
        >
          <Tab label="Company" value='companies' {...getProps('companies')} />
          {isActionAllowed({ authorRole: profileRole }) && (
            <Tab label="User Management" value='users' {...getProps('users')} />
          )}
          <Tab label="Message Templates" value='messageTemplates' {...getProps('messageTemplates')} />
          <Tab label="Contact Fields" value='contactFields' {...getProps('contactFields')} />
          {isActionAllowed({ authorRole: profileRole }) && (
            <Tab label="Appointment Types" value='appointmentTypes' {...getProps('appointmentTypes')} />
          )}
          {isActionAllowed({ authorRole: profileRole }) && (
            <Tab label="Webhook Integrations" value='webhooks' {...getProps('webhooks')} />
          )}
          {isActionAllowed({ authorRole: profileRole }) && (
            <Tab label="API Integrations" value='integrations' {...getProps('integrations')} />
          )}
        </Tabs>
        <CustomTabPanel value={tab} name='companies'>
          <Box sx={{ width: "100%" }}>
            <CustomFields
              profileRole={profileRole}
              isCompanyFields={true}
              showButtons={isActionAllowed({ authorRole: profileRole })}
            />
          </Box>
        </CustomTabPanel>
        {isActionAllowed({ authorRole: profileRole }) && (
          <CustomTabPanel value={tab} name='users'>
            <Box sx={{ width: "100%" }}>
              <UserManagment />
            </Box>
          </CustomTabPanel>
        )}
        <CustomTabPanel value={tab} name='messageTemplates'>
            <MessageTemplates />
        </CustomTabPanel>
        <CustomTabPanel value={tab} name='contactFields'>
          <Box sx={{ width: "100%" }}>
            <CustomFields
              profileRole={profileRole}
              isCompanyFields={false}
              showButtons={true}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} name='appointmentTypes'>
          <Box sx={{ width: "100%" }}>
            <AppointmentTypes />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tab} name='webhooks'>
          <Box sx={{ width: "100%" }}>
            <Integrations />
          </Box>
        </CustomTabPanel>
        {isActionAllowed({ authorRole: profileRole }) && (
          <CustomTabPanel value={tab} name='integrations'>
            <Box sx={{ width: "100%" }}>
              <ApiKeys />
            </Box>
          </CustomTabPanel>
        )}
      </Box>
    </MainWrapper>
  );
};

export default PersonalInformation;
