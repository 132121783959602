import React, { useState } from "react";
import { useSelector } from "react-redux";
import MainWrapper from "src/components/MainWrapper/view";
import { selectProfile } from "src/store/profile/profileSlice";
import AccessDenied from "../AccessDenied";
import { Tabs, Tab, Box, IconButton, Stack, Typography } from "@mui/material";
import BhiTable, { bhiTableNames } from "src/components/Table/view";
import {
  useDeleteAdminCompanyApi,
  useGetAdminCompaniesApi,
  useGetAdminCompanyApi,
} from "src/api/adminCompanies";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import { AdminCompany, Application, Subscription } from "src/constants/types";
import { GridColDef } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import dayjs from "dayjs";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const Companies = () => {
  const [tab, setTab] = useState(0);
  const { profile } = useSelector(selectProfile);
  const getCompanies = useGetAdminCompaniesApi();
  const deleteCompany = useDeleteAdminCompanyApi();
  const getCompany = useGetAdminCompanyApi();
  useHandleSelectedCompany();

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const initialCompany: AdminCompany = {
    id: "",
    name: "",
    messagingProfileId: "",
    createMessageProfile: true,
    application: { id: "", name: "", priceForSMS: 0, priceForMMS: 0 },
    tcrCampaignId: "",
    freeCreditsRemaining: 0,
    status: "",
    validContacts: 0,
    phoneNumber: "",
    phoneNumbers: [],
    timezone: "",
  };

  const formatColumns = () => {
    const columns: GridColDef[] = [
      {
        field: "name",
        flex: 1,
        headerName: "Company",
        sortable: true,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="column" height="100%" justifyContent="center">
              <Typography
                sx={{
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue}
              </Typography>
              <Typography fontSize={12} color="#ccc" sx={{ marginTop: "5px" }}>
                <Stack direction="row" justifyContent="space-between">
                  <Box
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {row.id}
                  </Box>
                  {row.id && (
                    <IconButton
                      size="small"
                      sx={{
                        transform: "scale(0.7)",
                        height: "3px",
                        width: "3px",
                        color: "#264597",
                        marginLeft: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(row.id?.toString());
                        toast.success("copied to clipboard");
                      }}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                  )}
                </Stack>
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        sortable: false,
        editable: false,
      },
      {
        field: "phoneNumbers",
        headerName: "Phone Numbers",
        valueGetter: (value, row) => row.phoneNumbers,
        renderCell: (row) => {
          if (!row.formattedValue) {
            return "";
          }
          if (Array.isArray(row.formattedValue)) {
            return row.formattedValue.map((m) => m.number).join(",");
          }
        },
        sortable: false,
        editable: false,
      },
      {
        field: "messagingProfileId",
        headerName: "Messaging Profile ID",
        flex: 1,
        sortable: false,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              height="100%"
              width="100%"
            >
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue}
              </Box>
              {row.formattedValue && (
                <IconButton
                  size="small"
                  sx={{
                    transform: "scale(0.7)",
                    color: "#264597",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      row.formattedValue?.toString()
                    );
                    toast.success("copied to clipboard");
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
      {
        field: "tcrCampaignId",
        headerName: "TCR Campaign ID",
        sortable: false,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue}
                {row.formattedValue && (
                  <IconButton
                    size="small"
                    sx={{
                      transform: "scale(0.7)",
                      color: "#264597",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        row.formattedValue?.toString()
                      );
                      toast.success("copied to clipboard");
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                )}
              </Box>
            </Stack>
          );
        },
      },
      {
        field: "application",
        hideable: false,
        headerName: "Application",
        valueGetter: (value, row) => row.application,
        renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {(row.value as Application).name}
              </Box>
            </Stack>
          );
        },
        sortable: true,
        editable: true,
      },
      {
        field: "subscription.plan",
        hideable: false,
        headerName: "Plan",
        valueGetter: (value, row) => row.subscription,
        renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {(row.value as Subscription)?.plan?.name}
              </Box>
            </Stack>
          );
        },
        sortable: false,
        editable: true,
      },

      {
        field: "subscription.startDate",
        hideable: false,
        headerName: "Start Date",
        flex: 1,
        valueGetter: (value, row) => row.subscription,
        renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {(row.value as Subscription)?.startDate
                  ? dayjs((row.value as Subscription)?.startDate).format(
                      "YYYY-MM-DD hh:mm A"
                    )
                  : ""}
              </Box>
            </Stack>
          );
        },
        sortable: false,
        editable: true,
      },
      {
        field: "subscription.autorenew",
        hideable: false,
        headerName: "Auto Renew",
        valueGetter: (value, row) => row.subscription,
        renderCell: (row) => {
          return (
            <Stack direction="row" justifyContent="space-between">
              <Box
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {(row.value as Subscription)?.autoRenew ? "On" : "Off"}
              </Box>
            </Stack>
          );
        },
        sortable: false,
        editable: false,
      },
    ];

    return columns;
  };

  const getProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  if (!profile) {
    return null;
  }

  const isBhiAdmin = profile?.type === "admin";

  if (!isBhiAdmin) {
    return <AccessDenied />;
  }

  return (
    <MainWrapper>
      <Box sx={{ width: "100%" }}>
        <Tabs value={tab} onChange={handleTabChange} aria-label="companies tab">
          <Tab label="Companies" {...getProps(0)} />
        </Tabs>
        <CustomTabPanel value={tab} index={0}>
          <Box sx={{ width: "100%" }}>
            <BhiTable<AdminCompany>
              tableName={bhiTableNames.companies}
              formatColumns={formatColumns}
              fetchData={getCompanies}
              getItem={getCompany}
              deleteItem={deleteCompany}
              initialData={initialCompany}
              allowActions={true}
              pageSize={100}
            />
          </Box>
        </CustomTabPanel>
      </Box>
    </MainWrapper>
  );
};

export default Companies;
