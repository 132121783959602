import React, { SyntheticEvent, CSSProperties, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, Button, Chip, Paper } from "@mui/material";
import Cancel from "@mui/icons-material/Cancel";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
type SelectWithSearchProps = {
  label: string;
  placeholder: string;
  disabled?: boolean;
  disableClearable?: boolean;
  showArchived?: boolean;
  showResultsUnderSearchTextBox?: boolean;
  isValidNewItem?: (input: string) => boolean;
  options: { text: string; value: string; isArchived?: boolean, status?:string }[];
  values: string[];
  handleAddItem?: (text: string) => void;
  handleSelectChange: (
    event: SyntheticEvent<Element, Event> | undefined,
    value: {
      text: string;
      value: string;
      hasWarning?: boolean;
      isArchived?: boolean;
    }[]
  ) => void;
  entity?: string;
  style: CSSProperties;
};

function SelectWithSearch({
  label,
  placeholder,
  options,
  values,
  handleSelectChange,
  style,
  handleAddItem,
  disabled,
  disableClearable,
  showResultsUnderSearchTextBox,
  isValidNewItem,
  entity,
}: SelectWithSearchProps) {
  const [inputValue, setInputValue] = useState("");
  const filter = createFilterOptions();
  const selectedValues = React.useMemo(
    () => options.filter((v) => values.includes(v.value)),
    [values, options]
  );

  const addItem = (item: string) => {
    if (handleAddItem) {
      handleAddItem(item);
    }
  };

  const getStatusIcon = (status: string | undefined) => {
    if (!status) {
      return <RadioButtonUncheckedIcon htmlColor="grey" />
    }
    switch (status) {
      case "unconfirmed":
        return <RadioButtonUncheckedIcon htmlColor="grey" />
      case "accepted":
        return <CheckCircleOutlineIcon style={{fill: "green"}} />
      case "declined":
        return <HighlightOffIcon style={{fill: "red"}} />
    }
  }

  return (
    <Autocomplete
      multiple
      style={style}
      id="checkboxes-tags-demo"
      options={options}
      disabled={!!disabled}
      disableClearable={disableClearable}
      disableCloseOnSelect
      noOptionsText={"No options"}
      getOptionDisabled={(option: {
        text: string;
        value: string;
        hasWarning?: boolean;
        isArchived?: boolean;
        isDisabled?: boolean;
      }) => {
        return !!option.isDisabled;
      }}
      getOptionLabel={(option: {
        text: string;
        value: string;
        hasWarning?: boolean;
        isArchived?: boolean;
        isDisabled?: boolean;
      }) => {
        if (option.isArchived) {
          return `${option.text} (Archived)`;
        } else {
          return option.hasWarning ? `${option.text} ⚠` : option.text;
        }
      }}
      value={selectedValues}
      filterOptions={(options: unknown[], params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        setInputValue(inputValue);
        return filtered;
      }}
      renderTags={(tagValue, getTagProps) => {
        if (showResultsUnderSearchTextBox) {
          return;
        }
        const tags = tagValue.map(
          (
            option: {
              text: string;
              isArchived?: boolean;
              isDisabled?: boolean;
            },
            index
          ) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              deleteIcon={
                option.isArchived || option.isDisabled ? <></> : <Cancel />
              }
              {...getTagProps({ index })}
              label={`${option.text} ${
                option.isArchived
                  ? "(Archived)"
                  : option.isDisabled
                  ? "(Dynamic)"
                  : ""
              }`}
            />
          )
        );
        return <Box>{tags}</Box>;
      }}
      renderOption={(
        props,
        option: { text: string; isArchived: boolean; isDisabled?: boolean },
        { selected }
      ) => {
        if (option.isArchived) {
          return null;
        }
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              disabled={option.isDisabled || option.isArchived}
              checked={selected}
            />
            {option.text}
          </li>
        );
      }}
      PaperComponent={({ children }) => {
        return (
          <Paper>
            {children}
            {handleAddItem &&
              inputValue &&
              (isValidNewItem ? isValidNewItem(inputValue) : true) &&
              !options.find((v) => v.text === inputValue) && (
                <Button
                  sx={{
                    marginLeft: entity === 'Contact' ? '5px' : "25px",
                    marginBottom: entity === 'Contact' ? '5px' : "25px",
                    marginTop: entity === 'Contact' ? '-5px' : "25px",
                  }}
                  variant="contained"
                  onMouseDown={() => {
                    addItem(inputValue);
                  }}
                >
                  Add New {entity ? entity : 'Item'}
                </Button>
              )}
          </Paper>
        );
      }}
      onChange={handleSelectChange}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <>
            <TextField {...params} label={label} placeholder={placeholder} />
            {showResultsUnderSearchTextBox && (
              <Box sx={{ marginTop: "10px" }}>
                {selectedValues
                  .map((option) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip
                      icon={getStatusIcon(option.status)}
                      sx={{ width:'100%', marginBottom: '5px', textAlign: 'left', "&.MuiChip-root": {
                        display: 'flex',
                        justifyContent:'space-between'
                      }, "& .MuiChip-label": {
                        flex: 1,
                      } }}
                      deleteIcon={<Cancel />}
                      onDelete={() => {
                        const options = selectedValues.filter(s => s.value !== option.value);
                        handleSelectChange(undefined, options);
                      }}
                      label={option.text}
                    />
                  ))}
              </Box>
            )}
          </>
        );
      }}
    />
  );
}

export default SelectWithSearch;
