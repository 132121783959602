import React, { SetStateAction, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { InboxContact, User } from "src/constants/types";
import EditIcon from "@mui/icons-material/Edit";
import { useAssignUserApi } from "src/api/contacts";
import { selectedCompany } from "src/store/company/companySlice";
import { useSelector } from "react-redux";

type ChatHeaderProps = {
  contact: InboxContact | null;
  handleContactEdit: (contactId: string) => void;
  refreshContacts: React.Dispatch<SetStateAction<void>>;
  users: User[];
};

const ChatHeader = ({
  contact,
  handleContactEdit,
  refreshContacts,
  users,
}: ChatHeaderProps) => {
  if (!contact) {
    return null;
  }
  const selectedCompanyId = useSelector(selectedCompany);
  const [assignedTo, setAssignedTo] = useState<string>(
    contact.contact.assignedTo || "unassign"
  );
  const assignUser = useAssignUserApi();

  const formatName = (): string => {
    if (contact.contact.firstName || contact.contact.lastName) {
      return `${contact.contact.firstName || ""} ${
        contact.contact.lastName || ""
      }`;
    }

    return contact.contact.phoneNumber;
  };

  const formatUserName = (user: User): string => {
    if (user.firstName || user.lastName) {
      return `${user.firstName || ""} ${user.lastName || ""}`;
    }

    return user.phoneNumber;
  };

  return (
    <>
      <Box
        width={"100%"}
        sx={{
          maxHeight: "60%",
          backgroundColor: "#F8FAFF",
          boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
          paddingLeft: "-1px",
        }}
      >
        <TextField
          disabled
          value={formatName()}
          color="success"
          style={{ justifyContent: "center", alignItems: "center" }}
          sx={{
            height: "60px",
            backgroundColor: "#EDEDED",
            border: "none",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          variant="standard"
          fullWidth
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <Box sx={{ paddingLeft: "10px" }}>
                {!contact.contact.id && <Box sx={{ padding: 1 }}>To:</Box>}
              </Box>
            ),
            endAdornment: (
              <Box sx={{ display: "flex" }}>
                <Box sx={{ paddingLeft: "10px" }}>
                  {!contact.contact.id && <Box sx={{ padding: 1 }}>To:</Box>}
                </Box>
                <Typography
                  sx={{
                    marginRight: "10px",
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  Assignee:
                </Typography>
                <FormControl variant="standard">
                  <Select
                    labelId="bhi-simple-select-label"
                    id="bhi-simple-select-user"
                    value={assignedTo}
                    onChange={({ target }) => {
                      setAssignedTo(target.value);
                      if (selectedCompanyId) {
                        assignUser(
                          {
                            userId:
                              target.value === "unassign" ? "" : target.value,
                          },
                          contact.contact.id,
                          selectedCompanyId!
                        ).then(() => {
                          refreshContacts();
                        });
                      }
                    }}
                    sx={{
                      minWidth: "150px",
                      fontSize: "15px",
                      marginTop: '5px',
                      "& .MuiInputBase-input": {
                        paddingBottom: 0,
                      },
                    }}
                  >
                    <MenuItem value={"unassign"} key={"unassign"}>
                      Unassigned
                    </MenuItem>
                    {users?.map((item) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {formatUserName(item)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <IconButton
                  onClick={() => {
                    handleContactEdit(contact.contact.id);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default ChatHeader;
