import { useCallback } from "react";
import apiClient from "../axiosClient";
import {
  GetAppointmentsApiParams,
  GetAppointmentApiParams,
  CreateAppointmentApiParams,
  DeleteAppointmentApiParams,
  AssignAppointmentContactsApiParams,
  RemoveAppointmentContactsApiParams,
  AddBulkAppointmentsApiParams,
} from "./types";

export function useGetAppointmentsApi(): GetAppointmentsApiParams {
  return useCallback((params) => {
    return apiClient.get(`/appointments`, { params });
  }, []);
}

export function useGetAppointmentApi(): GetAppointmentApiParams {
  return useCallback(
    (appointmetTypeId, companyId) =>
      apiClient.get(`/appointments/${appointmetTypeId}`, {
        params: { companyId },
      }),
    []
  );
}

export function useCreateAppointmentApi(): CreateAppointmentApiParams {
  return useCallback(
    (
      name: string,
      appointmentTypeId: string,
      startDate: string,
      endDate: string,
      companyId: string,
      description?: string,
      location?: string,
      notification?: string
    ) =>
      apiClient.post(
        `/appointments`,
        {
          name,
          appointmentTypeId,
          startDate,
          endDate,
          description,
          location,
          notification,
        },
        { params: { companyId } }
      ),
    []
  );
}

export function useUpdateAppointmentApi() {
  return useCallback(
    (
      appointmentId: string,
      name: string,
      appointmentTypeId: string,
      startDate: string,
      endDate: string,
      companyId: string,
      description?: string,
      location?: string,
      notification?: string
    ) =>
      apiClient.put(
        `/appointments/${appointmentId}`,
        {
          name,
          startDate,
          endDate,
          appointmentTypeId,
          description,
          location,
          notification,
        },
        { params: { companyId } }
      ),
    []
  );
}

export function useDeleteAppointmentApi(): DeleteAppointmentApiParams {
  return useCallback(
    (appointmentTypeId, companyId) =>
      apiClient.delete(`/appointments/${appointmentTypeId}`, {
        params: { companyId },
      }),
    []
  );
}

export function useAssignAppointmentContactsApi(): AssignAppointmentContactsApiParams {
  return useCallback(
    (appointmentContactId, appointmentId, companyId) =>
      apiClient.post(
        `/appointments/${appointmentId}/contacts`,
        { contactId: appointmentContactId, confirmationStatus: "unconfirmed" },
        { params: { companyId } }
      ),
    []
  );
}

export function useRemoveAppontmentContactsApi(): RemoveAppointmentContactsApiParams {
  return useCallback(
    (appointmentContactId, appointmentId, companyId) =>
      apiClient.delete(
        `/appointments/${appointmentId}/contacts/${appointmentContactId}`,
        { params: { companyId } }
      ),
    []
  );
}

export function useAddBulkAppointmentsApi(): AddBulkAppointmentsApiParams {
  return useCallback(
    (params, companyId) =>
      apiClient.post(`/appointments/csv-upload`, params, {
        params: { companyId },
      }),
    []
  );
}
