import React, { useEffect, useState } from "react";
//import moment from "moment";
import Calendar from "./Calendar";
import "./index.scss";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { Appointment, AppointmentType } from "src/constants/types";
import {
  useGetAppointmentApi,
  useGetAppointmentsApi,
} from "src/api/appointments";
import { GetAppointmentParams } from "src/api/appointments/types";
import { useGetAppointmentTypesApi } from "src/api/appointmentTypes";
import dayjs from "dayjs";
import CreateAppointmentModal from "./CreateAppointmentModal/CreateAppointmentModal";
import { useCreateAppointmentTypeApi } from "src/api/appointmentTypes";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { SlotInfo } from "react-big-calendar";
import "./CreateAppointmentModal/styles.scss";
import DownloadCsvModal from "./DownloadCsvModal/view";
import { getRandomHexColor } from "../Settings/Components/AppointmentTypes/helpers";

const components = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: (props: any) => {
    return (
      <Box
        sx={{
          backgroundColor: props.event.data.appointment.appointmentType.color,
          borderRadius: "4px",
          paddingTop: "4px",
          paddingBottom: "2px",
          paddingLeft: "5px",
          paddingRight: "5px",
          fontSize: "12px",
          height: "100%",
        }}
      >
        {props.event.data.appointment.name}
      </Box>
    );
  },
};

export default function AppointmentCalendar() {
  const selectedCompanyId = useSelector(selectedCompany);
  const getAppointments = useGetAppointmentsApi();
  const getAppointment = useGetAppointmentApi();
  const createDefaultAppointmentType = useCreateAppointmentTypeApi();
  const getAppointmentTypes = useGetAppointmentTypesApi();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDownloadCsvModal, setShowDownloadCsvModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] =
    useState<Appointment | null>(null);
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>();
  const [filterAppointmentTypeId, setFilterAppointmentTypeId] =
    useState<string>("All");
  const [startDate, setStartDate] = useState<string>(
    dayjs().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().startOf("week").add(7, "d").format("YYYY-MM-DD")
  );

  const [slotStartDate, setSlotStartDate] = useState<string>();
  const [slotEndDate, setSlotEndDate] = useState<string>();

  const initialAppointment: Appointment = {
    name: "",
    startDate: startDate,
    endDate: endDate,
    companyId: selectedCompanyId!,
  };

  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const onRangeChange = (
    range:
      | Date[]
      | {
          start: Date;
          end: Date;
        }
  ) => {
    if (Array.isArray(range)) {
      setStartDate(dayjs(range[0]).format("YYYY-MM-DD"));
      setEndDate(
        dayjs(range[range.length - 1])
          .add(1, "d")
          .format("YYYY-MM-DD")
      );
    } else if (typeof range === "object" && range !== null) {
      setStartDate(dayjs(range.start).format("YYYY-MM-DD"));
      setEndDate(dayjs(range.end).add(1, "d").format("YYYY-MM-DD"));
    }
  };

  const onSelectSlot = (slotInfo: SlotInfo) => {
    setSelectedAppointment(null);
    setSlotStartDate(dayjs(slotInfo.start).toISOString());
    setSlotEndDate(dayjs(slotInfo.end).toISOString());
    setShowCreateModal(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectEvent = (event: any) => {
    if (event.data.appointment.id) {
      getAppointment(event.data.appointment.id, selectedCompanyId!).then(
        (response) => {
          setSelectedAppointment(response.data);
          setShowCreateModal(true);
        }
      );
    }
  };

  const loadAppointments = () => {
    if (!appointmentTypes || !selectedCompanyId) {
      return;
    }
    const typeIds = appointmentTypes.map((t) => t.id);

    if (!typeIds.length) {
      return;
    }
    
    const params: GetAppointmentParams = {
      companyId: selectedCompanyId,
      appointmentTypeIds:
        filterAppointmentTypeId !== "All"
          ? filterAppointmentTypeId
          : typeIds.join(","),
      statuses: "active",
      startDateInterval: startDate!,
      endDateInterval: endDate!,
      limit: 100000,
      offset: 0,
    };
    getAppointments(params).then((response) => {
      setAppointments(response.data.items);
    });
  };

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }

    const typesParams = {
      limit: 10000,
      offset: 0,
      companyId: selectedCompanyId,
      statuses: "active,archived",
    };
    getAppointmentTypes(typesParams).then((responseTypes) => {
      if (responseTypes.data.count === 0) {
        createDefaultAppointmentType("Default", getRandomHexColor(), selectedCompanyId).then((response) => {
          setAppointmentTypes([response.data]);
        })
      } else {
        setAppointmentTypes(responseTypes.data.items);
      }
    });
  }, [selectedCompanyId]);

  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    loadAppointments();
  }, [
    selectedCompanyId,
    startDate,
    endDate,
    appointmentTypes,
    filterAppointmentTypeId,
  ]);

  const getEvents = (appointments: Appointment[]) => {
    return appointments.map((a) => {
      return {
        start: new Date(a.startDate!),
        end: new Date(a.endDate!),
        data: { appointment: a },
      };
    });
  };

  const handleChangeFilterAppointmentType = ({
    target,
  }: SelectChangeEvent<string>) => {
    const type = appointmentTypes?.find((a) => a.id === target.value);
    if (type) {
      setFilterAppointmentTypeId(type.id);
    } else {
      setFilterAppointmentTypeId("All");
    }
  };

  return (
    <>
      {appointmentTypes && (
        <Stack
          direction="row"
          sx={{ marginTop: "20px" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <FormControl size="small" sx={{ width: "192px" }}>
              <InputLabel>Appointment Type</InputLabel>
              <Select
                inputProps={{}}
                size="small"
                sx={{
                  marginBottom: "5px",
                  minWidth: "100%",
                  padding: 0,
                  width: "77%",
                }}
                label="Appointment Type"
                MenuProps={{
                  disableScrollLock: true,
                }}
                value={filterAppointmentTypeId}
                onChange={handleChangeFilterAppointmentType}
                name="fieldName"
              >
                <MenuItem value={"All"} key={"all"}>
                  All
                </MenuItem>
                {appointmentTypes
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((c) => (
                    <MenuItem value={c.id} key={c.id}>
                      {c.name} {c.status === 'active' ? '' : '(archived)'}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              sx={{
                width: "127px",
                marginTop: "-20px",
              }}
              onClick={() => {
                setShowDownloadCsvModal(true);
              }}
            >
              Import
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                width: "127px",
                marginLeft: "20px",
                marginTop: "-20px",
              }}
              onClick={() => {
                setSelectedAppointment(null);
                setSlotStartDate(undefined);
                setSlotEndDate(undefined);
                setShowCreateModal(true);
              }}
            >
              Create
            </Button>
          </Box>
        </Stack>
      )}
      <Calendar
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        onRangeChange={onRangeChange}
        defaultView="week"
        selectable
        scrollToTime={new Date(0, 0, 0, 6, 0, 0)}
        views={["month", "week", "day", "agenda"]}
        events={getEvents(appointments)}
        components={components}
      />
      {showCreateModal && (
        <CreateAppointmentModal
          slotStartDate={slotStartDate}
          slotEndDate={slotEndDate}
          appointmentTypes={appointmentTypes!}
          show={showCreateModal}
          setShowModal={setShowCreateModal}
          appointment={selectedAppointment}
          initialAppointment={initialAppointment}
          refreshAppointments={loadAppointments}
        />
      )}
      {showDownloadCsvModal && (
        <DownloadCsvModal
          show={showDownloadCsvModal}
          setShowModal={setShowDownloadCsvModal}
          refreshAppointments={loadAppointments}
        />
      )}
    </>
  );
}
