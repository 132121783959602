import { ScheduledType } from "src/api/campaigns/types";
import { MessageType } from "./types";
import { Message } from "src/constants/types";
import dayjs from "dayjs";

export const initialValues = {
  name: `${dayjs(new Date()).format("YYYY-MM-DD")} - Annual Campaign`,
  metadataName: "",
  contactMetadataId: "",
  groupIds: [],
  keywordIds: [],
  scheduledMessages: [] as Message[],
  optOutText: "Reply STOP to opt-out",
  isOptOutEnabled: false,
};
export const initialMessage: Message = {
  text: "",
  type: "sms" as MessageType,
  metadataName: "",
  subject: "",
  links: [],
  linksWithMimeType: [],
  position: 1,
  scheduledType: "delay" as ScheduledType,
  delay: '540m',
  time: null,
};
export const ITEM_HEIGHT = 48;

export const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
