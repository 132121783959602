import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  CircularProgress,
} from "@mui/material";
import { AdminCompany, Application } from "src/constants/types";
import {
  editAdminCompanyValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import * as Yup from "yup";
import {
  useGetAdminApplicationsApi,
  useUpdateAdminCompanyApi,
} from "src/api/adminCompanies";
import moment from "moment";
import toast from "react-hot-toast";

type CompanyInfoProps = {
  company: AdminCompany;
  refreshCompany: React.Dispatch<React.SetStateAction<void>>;
};

const CompanyInfoTab = (props: CompanyInfoProps) => {
  const { company, refreshCompany } = props;

  const getAdminApplications = useGetAdminApplicationsApi();
  const updateAdminCompany = useUpdateAdminCompanyApi();

  const [loading, setLoading] = useState<boolean>(true);

  const [formValues, setFormValues] = useState<AdminCompany>(company);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [applications, setApplications] = useState<Application[]>();

  const timeZones = moment.tz.zonesForCountry("US");

  useEffect(() => {
    getAdminApplications({ limit: 10000, offset: 0 }).then((response) => {
      if (response.data.items) {
        setApplications(response.data.items);
        setLoading(false);
      }
    });
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValues((prevState: AdminCompany) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeApplication = ({ target }: SelectChangeEvent<string>) => {
    const application = applications?.find((a) => a.id === target.value);
    if (application) {
      setFormValues({
        ...formValues,
        application: application,
      });
    }
  };

  const handleChangeTimezone = ({ target }: SelectChangeEvent<string>) => {
    setFormValues({
      ...formValues,
      timezone: target.value,
    });
  };

  const validate = (values: typeof formValues) => {
    try {
      editAdminCompanyValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const isValidForm = (values: typeof formValues) => {
    try {
      editAdminCompanyValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return true;
    } catch {
      return false;
    }
  };

  const handleAdminCompanyInfoSave = () => {

    const isValid = validate(formValues);
    if (isValid) {
      updateAdminCompany(
        {
          name: formValues.name,
          applicationId: formValues.application.id,
          timezone: formValues.timezone,
          messagingProfileId: formValues.messagingProfileId,
          tcrCampaignId: formValues.tcrCampaignId,
        },
        formValues.id
      ).then(() => {
        refreshCompany();
        toast.success('company info is updated');
      });
    }
  };

  return (
    <Box sx={{ marginTop: "30px" }}>
      {loading && <CircularProgress />}
      {!loading && applications && (
        <Box>
          <Stack width="100%" gap="5px" direction="row">
            <TextField
              sx={{ width: "49%" }}
              id="outlined-basic"
              label={"Name"}
              name="name"
              variant="outlined"
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors?.name || ""}
            />
            <FormControl sx={{ width: "49%", marginLeft: "1%" }}>
              <InputLabel>Application</InputLabel>
              <Select
                label="Application"
                MenuProps={{ disableScrollLock: true }}
                value={formValues.application.id}
                onChange={handleChangeApplication}
                name="fieldName"
              >
                {applications
                  .sort((a, b) =>
                    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                  )
                  .map((c) => (
                    <MenuItem value={c.id} key={c.id}>
                      {c.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            width="100%"
            gap="5px"
            direction="row"
            sx={{ marginTop: "20px" }}
          >
            <TextField
              sx={{ width: "49%", marginRight: "1%" }}
              id="outlined-basic"
              label={"Message Profile ID"}
              name="messagingProfileId"
              variant="outlined"
              value={formValues.messagingProfileId}
              onChange={handleChange}
              error={!!errors.messagingProfileId}
              helperText={errors?.messagingProfileId || ""}
            />
            <TextField
              sx={{ width: "49%" }}
              id="outlined-basic"
              label={"TCR Campaign ID"}
              name="tcrCampaignId"
              variant="outlined"
              value={formValues.tcrCampaignId}
              onChange={handleChange}
              error={!!errors.tcrCampaignId}
              helperText={errors?.tcrCampaignId || ""}
            />
          </Stack>
          <Stack
            width="100%"
            gap="5px"
            direction="row"
            sx={{ marginTop: "20px" }}
          >
            <FormControl sx={{ width: "49%" }}>
              <InputLabel>Time zone</InputLabel>
              <Select
                label="Time zone"
                MenuProps={{ disableScrollLock: true }}
                value={formValues.timezone}
                onChange={handleChangeTimezone}
                name="timezone"
              >
                {timeZones.map((item) => {
                      return (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Button
              size="small"
              disabled={!isValidForm(formValues)}
              variant="contained"
              style={{ width: "150px", marginTop: "20px" }}
              onClick={handleAdminCompanyInfoSave}
            >
              Save
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default CompanyInfoTab;
