import React, { useState } from "react";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Chip, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import AttachFileUrlModal from "../AttachFileUrlModal/view";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {FileLinkWithMimeType} from "../../constants/types";
import ChatFileIcon from "../ChatFileIcon/view";

type FileUploadInputType = {
  links: FileLinkWithMimeType[];
  updateLinks: (links: FileLinkWithMimeType[]) => void;
  disabled: boolean;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
        backgroundColor: "#f5f5f9",
        border: "1px solid #dadde9",
    }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const FileUploaderInput = ({ links, updateLinks, disabled }: FileUploadInputType) => {
  const [showModal, setShowModal] = useState(false);
  const handleDelete = (index: number) => {
    const newLinks = [...links.slice(0, index), ...links.slice(index + 1)];
    updateLinks(newLinks);
  };

  return (
    <FormControl style={{ marginBottom: "15px", width: '100%' }}>
      {!!links?.length && (
        <Stack
          direction="column"
          spacing={1}
          style={{ marginBottom: "15px", alignItems: "start" }}
        >
          {links.map((link, index) => {
            return (
              <HtmlTooltip placement="top" arrow key={index} title={
                <>
                  <ChatFileIcon url={link.url} mimeType={link.mimeType} key={index}/>
                </>
              }>
                <Chip
                  disabled={!!disabled}
                  label={link.url}
                  variant="outlined"
                  onDelete={() => handleDelete(index)}
                />
              </HtmlTooltip>
            );
          })}
        </Stack>
      )}
      <Button
        component="label"
        style={{ width: "150px" }}
        variant="contained"
        startIcon={<UploadFileIcon />}
        onClick={() => setShowModal(true)}
        disabled={links?.length === 10 || !!disabled}
      >
        Attach
      </Button>
      {showModal && (
        <AttachFileUrlModal
          show={showModal}
          setShowModal={setShowModal}
          handleFileChange={(link: FileLinkWithMimeType) => {
            updateLinks([...links, link]);
          }}
        />
      )}
    </FormControl>
  );
};

export default FileUploaderInput;
