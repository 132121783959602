import {
  Box,
  Button,
  IconButton,
  Popper,
  Stack,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CreateAdminCompanyModal from "src/pages/Companies/CreateCompanyModal/view";
import {
  AdminCompany,
  AppointmentType,
  DefaultCustomField,
  MessageTemplate,
} from "src/constants/types";
import CreateMessageTemplateModal from "src/pages/Settings/Components/MessageTemplates/CreateMessageTemplateModal/view";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useNavigate } from "react-router-dom";
import useConfirmationDialog from "../ConfirmationDialog/view";
import { useSelector } from "react-redux";
import { selectProfile } from "src/store/profile/profileSlice";
import CreateAppointmentTypeModal from "src/pages/Settings/Components/AppointmentTypes/CreateAppointmentTypeModal/view";

export enum bhiTableNames {
  companies = "companies",
  messageTemplates = "messageTemplates",
  appointmentTypes = "appointmentTypes",
}

interface IProps<TEntity> {
  selectedCompanyId?: string;
  tableName: bhiTableNames;
  fetchData: (
    params: object
  ) => Promise<{ data: { items: TEntity[]; count: number } }>;
  getItem: (id: string, companyId?: string) => Promise<{ data: TEntity }>;
  deleteItem: (id: string, companyId?: string) => Promise<void>;
  pageSize: number;
  initialData: TEntity;
  metadata?: DefaultCustomField[];
  allowActions?: boolean;
  formatColumns: (handleEditItem: (id: string) => void) => GridColDef[];
  restoreItem?: (
    appointmentTypeId: string,
    companyId: string
  ) => Promise<void>;
  status?: string;
}

function BhiTable<TEntity>({
  fetchData,
  getItem,
  deleteItem,
  pageSize = 5,
  initialData,
  formatColumns,
  tableName,
  selectedCompanyId,
  metadata,
  allowActions,
  restoreItem,
}: IProps<TEntity>) {
  const { profile } = useSelector(selectProfile);

  const handleItemEdit = (id: string) => {
    if (selectedCompanyId) {
      getItem(id, selectedCompanyId).then((response) => {
        setData(response.data);
        setCreateModal(true);
      });
    } else {
      getItem(id).then((response) => {
        setData(response.data);
        setCreateModal(true);
      });
    }
  };

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      status: false,
    });

  const handleItemDelete = (id: string) => {
    if (selectedCompanyId) {
      deleteItem(id, selectedCompanyId).then(loadData);
    } else {
      deleteItem(id).then(loadData);
    }
  };

  const handleRestoreItem = (id: string) => {
    if (restoreItem) {
      restoreItem(id, selectedCompanyId!).then(loadData);
    }
  }

  const subtitleText: Record<string, string> = {
    companies: "Company",
    messageTemplates: "Template",
    appointmentTypes: "Appointment Type",
  };

  const newButtonText: Record<string, string> = {
    companies: "Add New Company",
    messageTemplates: "Add New Template",
    appointmentTypes: "Add New Appointment Type",
  };

  const columns = formatColumns(handleItemEdit);
  const [rows, setRows] = useState<TEntity[]>();
  const [data, setData] = useState<TEntity>(initialData);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [showCreateModal, setCreateModal] = useState(false);
  const navigate = useNavigate();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();

  const anchorEl = useRef<HTMLElement | null>(null);
  const [rowId, setRowId] = useState<string>();
  const [status, setStatus] = useState<string>("");
  const open = Boolean(anchorEl);

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: pageSize,
    });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any


  const getQueryParams = () => {
    const params: {
      limit: number;
      offset: number;
      sort: string;
      query: string | undefined;
      statuses?: string;
      companyId?: string;
    } = {
      limit: pageSize,
      offset: paginationModel.page * pageSize,
      sort: `${sortModel[0].field}_${sortModel[0].sort}`,
      query: searchQuery,
    };

    if (selectedCompanyId) {
      params.companyId = selectedCompanyId;
    }

    if (tableName === bhiTableNames.appointmentTypes) {
      params.statuses = "active,archived";
    }

    if (!searchQuery) {
      delete params.query;
    }

    return params;
  };

  const capitalize = (s: string) =>
    s && String(s[0]).toUpperCase() + String(s).slice(1);

  const loadData = async () => {
    setIsLoading(true);
    const response = await fetchData(getQueryParams());
    setRows(response.data.items);
    setTotalCount(response.data.count);
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [paginationModel, sortModel, searchQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl.current = event.currentTarget.parentElement;
    const id = event.currentTarget.parentElement!.dataset.id!;
    setRowId(id);
    if (anchorEl.current && restoreItem) {
      const isArchived = anchorEl.current.classList.contains('archived');
      setStatus(isArchived ? 'archived' : 'archive');
    }
  };

  const subtitle = subtitleText[tableName];
  return (
    <>
      <Box sx={{ paddingTop: "20px;", position: "relative" }}>
        <Stack
          direction={"row"}
          sx={{
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            border: "1px solid #F0F0F0",
            padding: "15px",
          }}
          justifyContent={"space-between"}
          height="40px"
        >
          <TextField
            size="small"
            onChange={handleChange}
            InputProps={{ endAdornment: <SearchIcon /> }}
          />
          {(tableName === "appointmentTypes" ||
            tableName === "companies" ||
            (tableName === "messageTemplates" &&
              profile &&
              profile?.role?.name !== "company_user")) && (
            <Button
              sx={{ height: "40px;" }}
              size="small"
              variant="contained"
              onClick={() => {
                setData(initialData);
                setCreateModal(true);
              }}
            >
              {newButtonText[tableName] ?? "Add New Item"}
            </Button>
          )}
          {tableName === bhiTableNames.appointmentTypes && (
            <CreateAppointmentTypeModal
              initialAppointmentType={initialData as AppointmentType}
              appointmentType={data as AppointmentType}
              show={showCreateModal}
              setShowModal={setCreateModal}
              refreshAppointmentTypes={loadData}
            />
          )}
          {tableName === bhiTableNames.companies && (
            <CreateAdminCompanyModal
              initialCompany={initialData as AdminCompany}
              adminCompany={data as AdminCompany}
              show={showCreateModal}
              setShowModal={setCreateModal}
              refreshCompanies={loadData}
            />
          )}
          {tableName === bhiTableNames.messageTemplates && (
            <CreateMessageTemplateModal
              metadata={metadata}
              initialMessageTemplate={initialData as MessageTemplate}
              messageTemplate={data as MessageTemplate}
              show={showCreateModal}
              setShowModal={setCreateModal}
              refreshMessageTemplates={loadData}
            />
          )}
        </Stack>
        <Box sx={{ position: "relative" }}>
          <DataGrid
            slotProps={{
              cell: {
                onMouseEnter: handlePopoverOpen,
              },
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            getRowClassName={(params) => `${params.row.status}`}
            columns={columns}
            rows={rows}
            pagination
            disableMultipleRowSelection
            disableRowSelectionOnClick
            pageSizeOptions={[pageSize]}
            sortingMode="server"
            paginationMode="server"
            paginationModel={paginationModel}
            sortModel={sortModel}
            disableColumnFilter
            sortingOrder={["asc", "desc"]}
            rowSelection={false}
            rowCount={totalCount}
            loading={isLoading}
            onPaginationModelChange={(
              newPaginationModel: GridPaginationModel
            ) => {
              setPaginationModel(newPaginationModel);
            }}
            onSortModelChange={(sortModel) => {
              setSortModel(sortModel);
            }}
            sx={{
              fontSize: "12px",
              padding: 0,
              height: "calc(100vh - 250px)",
              "&, [class=MuiDataGrid]": {
                borderTop: "none",
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
              },
              "& .MuiDataGrid-columnSeparator": {
                opacity: "1 !important;",
              },
              "& .MuiDataGrid-container--top [role=row]": {
                backgroundColor: "rgb(37, 69, 151)",
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: 1,
                color: "white",
              },
              "& .MuiDataGrid-menuIconButton": {
                opacity: 1,
                color: "white",
              },
              "& .MuiDataGrid-columnHeader[data-field=subscription.autorenew] .MuiDataGrid-columnSeparator--sideRight, .MuiDataGrid-columnHeader[data-field=actions] .MuiDataGrid-columnSeparator--sideRight":
                {
                  display: "none",
                },
              "& .MuiDataGrid-columnSeparator:hover": {
                color: "white",
              },
            }}
          />
          {allowActions && (
            <Popper open={open} placement="right" anchorEl={anchorEl.current}>
              <Box
                sx={{
                  position: "fixed",
                  width: "90px",
                  right: "5px",
                  top: anchorEl.current ? "-20px" : "-40px",
                }}
              >
                <IconButton
                  sx={{
                    cursor: "pointer",
                    color: "#264597",
                    borderRadius: "50%",
                    marginLeft: "0px",
                    transform: "scale(0.7)",
                    backgroundColor: "transparent",
                    zIndex: 9999999,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (tableName === bhiTableNames.messageTemplates) {
                      handleItemEdit(rowId!);
                    }
                    if (tableName === bhiTableNames.companies) {
                      navigate(`/admin/${tableName}/edit/${rowId}`);
                    }
                    if (tableName === bhiTableNames.appointmentTypes) {
                      handleItemEdit(rowId!);
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{
                    cursor: "pointer",
                    color: "#264597",
                    transform: "scale(0.7)",
                    borderRadius: "50%",
                    zIndex: 999999,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    const actionText =
                      status === "archived"
                        ? "unarchive"
                        : status === "archive"
                        ? "archive"
                        : "delete";
                    const isDeletable = actionText === "delete" || actionText === 'archive';
                    openDialog({
                      title: `${capitalize(actionText)} ${subtitle}`,
                      content: `Are you sure you want to ${actionText} this ${subtitle}?`,
                      onConfirm: () => {
                        if (rowId) {
                          if (isDeletable) {
                            handleItemDelete(rowId!);
                          } else {
                            handleRestoreItem(rowId!);
                          }
                        }
                      },
                    });
                  }}
                >
                  {!restoreItem && <DeleteIcon />}
                  {restoreItem && status !== 'archived' && <ArchiveIcon />}
                  {restoreItem && status === 'archived' && <UnarchiveIcon />}
                </IconButton>
              </Box> 
            </Popper>
          )}
        </Box>
      </Box>
      <ConfirmationDialog />
    </>
  );
}

export default BhiTable;
