import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  KeyboardEvent,
} from "react";
import { StyledHeader } from "./styles";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "src/contexts/AuthContext";
import {
  selectedCompany,
  selectCompanies,
} from "src/store/company/companySlice";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedCompany } from "src/store/company/companySlice";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { selectProfile } from "src/store/profile/profileSlice";
import TextField from "@mui/material/TextField";
import { ClickAwayListener, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function Header() {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [searchCompanyTerm, setSearchCompanyTerm] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const companySearchRef = useRef<HTMLDivElement | HTMLTextAreaElement>(null);
  const authContext = useContext(AuthContext);
  const companyId = useSelector(selectedCompany);
  const selectedCompanyId =
    localStorage.getItem("bhiCurrentCompany") ?? companyId;
  const { profile } = useSelector(selectProfile);
  const isBhiAdmin = profile?.type === "admin";
  const companies = useSelector(selectCompanies);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    authContext?.setIsAuthenticated(false);
    localStorage.removeItem("a_token");
    localStorage.removeItem("r_token");
    localStorage.removeItem("bhiRefreshingToken");
    dispatch({ type: "logout" });
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChange = (companyId: string) => {
    localStorage.setItem("bhiCurrentCompany", companyId);
    window.location.reload();
  };

  useEffect(() => {
    if (open) {
      setSearchCompanyTerm("");
    }
  }, [open]);

  const filteredCompanies =
    companies &&
    [...companies]
      .filter(
        (c) =>
          c.name.toLowerCase().includes(searchCompanyTerm.toLowerCase()) ||
          c.id === selectedCompanyId
      )
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  return (
    <StyledHeader>
      {selectedCompanyId && (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
          }}
        >
          <FormControl
            style={{
              cursor: companies.length > 1 ? "pointer" : "default",
              width: "200px",
              marginRight: "20px",
            }}
            variant="standard"
          >
            <Select
              sx={{
                position: "relative",
                "& #bhi-select-company" : {
                  cursor: companies.length > 1 ? 'pointer' : 'default',
                },
                "& .MuiSelect-icon": {
                  display:
                    companies && companies.length === 1 ? "none" : "initial",
                },
                "& .MuiFormControl-root": {
                  cursor: companies.length > 1 ? "pointer" : "default",
                },
              }}
              onClick={() => {
                if (companies && companies.length === 1) {
                  return;
                }
                setOpen(!open);
                window.setTimeout(() => {
                  if (companySearchRef && companySearchRef.current) {
                    companySearchRef.current.focus();
                  }
                }, 50);
              }}
              labelId="demo-simple-select-label"
              id="bhi-select-company"
              value={selectedCompanyId}
              open={open}
              MenuProps={{
                disablePortal: false,
                PaperProps: {
                  sx: {
                    cursor: companies.length > 1 ? 'pointer' : 'default',
                    "& .MuiMenu-list": {
                      paddingTop: 0,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: "white",
                    },
                    "& .MuiMenuItem-root:first-child": {
                      backgroundColor: "white !important",
                    },
                  },
                },
              }}
              defaultValue={selectedCompanyId}
              label="Company"
            >
              {companies && companies.length > 1 && (
                <MenuItem
                  sx={{
                    backgroundColor: "white",
                    position: "sticky",
                    top: "0px",
                    zIndex: "999999",
                    paddingRight: 0,
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <TextField
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        height: "35px",
                        width: "100%",
                        paddingTop: 0,
                        paddingBottom: 0,
                      },
                      marginLeft: "-8px",
                    }}
                    id="nested-select-filter"
                    placeholder="Search..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    inputRef={companySearchRef}
                    value={searchCompanyTerm}
                    autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginLeft: "-10px" }}
                        >
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: false }}
                    onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                      e.stopPropagation();
                      if (
                        e.key === "ArrowDown" &&
                        companySearchRef &&
                        companySearchRef.current
                      ) {
                        companySearchRef.current.blur();
                        const companiesList =
                          document.getElementById("bhi-select-company");
                        if (companiesList) {
                          (
                            document.querySelectorAll(
                              ".companyName"
                            )[0] as HTMLElement
                          ).focus();
                        }
                      }
                    }}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchCompanyTerm(e.target.value);
                    }}
                  />
                </MenuItem>
              )}
              {filteredCompanies.map(({ id, name }) => {
                return (
                  <MenuItem
                    sx={{
                      display:
                        id === selectedCompanyId &&
                        !name.toLowerCase().includes(searchCompanyTerm)
                          ? "none"
                          : "block",
                      height:
                        id === selectedCompanyId &&
                        !name.toLowerCase().includes(searchCompanyTerm)
                          ? "0"
                          : "auto",
                    }}
                    className="companyName"
                    onClick={() => {
                      setOpen(false);
                      setSelectedCompany(id);
                      handleChange(id);
                    }}
                    value={id}
                    key={id}
                  >
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </ClickAwayListener>
      )}
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <AccountCircleIcon sx={{ fontSize: 40 }} />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key={"profile"} onClick={() => navigate("/profile")}>
          <Typography textAlign="center">{"My Profile"}</Typography>
        </MenuItem>
        {isBhiAdmin && (
          <MenuItem
            key={"companies"}
            onClick={() => navigate("/admin/companies")}
          >
            <Typography textAlign="center">{"Admin"}</Typography>
          </MenuItem>
        )}
        <MenuItem key={"logout"} onClick={handleLogout}>
          <Typography textAlign="center">{"Log out"}</Typography>
        </MenuItem>
      </Menu>
    </StyledHeader>
  );
}

export default Header;
