import "./styles.scss";
import React, { useEffect, useRef, useState } from "react";
import Document from "@tiptap/extension-document";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import HardBreak from "@tiptap/extension-hard-break";
import { EditorContent, useEditor } from "@tiptap/react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import {
  Button,
  ClickAwayListener,
  MenuItem,
  Menu,
  Box,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { configureSuggestions } from "./suggestion";
import { DefaultCustomField, MessageTemplate } from "src/constants/types";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";

export type TemplateTextProps = {
  text: string;
  messageTemplates: MessageTemplate[] | undefined;
  customFields: DefaultCustomField[];
  disabled?: boolean;
  updateText: (newText: string) => void;
  showConfirmationText: boolean;
  confirmationText: string;
  setFocus: boolean;
};

export default function TemplateText(props: TemplateTextProps) {
  const {
    text,
    updateText,
    messageTemplates,
    customFields,
    disabled,
    setFocus,
    confirmationText,
    showConfirmationText,
  } = props;
  const messageTemplateIconRef = useRef<HTMLButtonElement>(null);
  const [openEmojiModal, setOpenEmojiModal] = useState<boolean>(false);
  const [caretPosition, setCaretPosition] = useState<number>(1);
  const [hasFieldsWithoutDefaultValue, setHasFieldsWithoutDefaultValue] =
    useState<boolean>(false);

  const [openMessageTemplatesList, setOpenMessageTemplatesList] = useState<
    boolean | null
  >(null);
  const handleMessageTemplatesListClose = () => {
    setOpenMessageTemplatesList(false);
  };

  const formatMessage = (message: string) => {
    const formattedMessage = message
      .replaceAll("\n", "<br/>")
      .replaceAll(/{{[^{}]+(?=})}}/g, (match: string) => {
        return formatCustomFieldToHtml(match);
      });
    return formattedMessage;
  };

  const formatCustomFieldToHtml = (text: string) => {
    const formattedText = text.replaceAll("{{", "").replace("}}", "");
    const customField = customFields?.find((c) => c.name === formattedText);
    if (customField) {
      const html = `<span data-type="mention" class="mention" data-id="${customField.name}" style="background-color:#ccc; border-radius: 7px; padding-left:3px; padding-right: 3px;" data-label="${customField.description}" contenteditable="false">${customField.description}</span>`;
      return html;
    }
    return "";
  };

  const hasCustomFieldsWithoutDefaultValue = (message: string): void => {
    let result = false;
    const container = document.createElement("div");
    container.innerHTML = message;
    const fields = container.querySelectorAll("span.mention");
    fields.forEach((f) => {
      const customField = customFields.find(
        (cf) => cf.name === f.getAttribute("data-id")
      );
      if (customField && !customField.defaultValue) {
        result = true;
        return result;
      }
    });
    setHasFieldsWithoutDefaultValue(result);
  };

  const editor = useEditor({
    editable: !disabled,
    extensions: [
      Document,
      Paragraph,
      Text,
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => this.editor.commands.setHardBreak(),
          };
        },
      }),
      Mention.configure({
        deleteTriggerWithBackspace: true,
        HTMLAttributes: {
          class: "mention",
        },
        renderHTML({ node }) {
          return `${node.attrs.label}`;
        },
        suggestion: configureSuggestions(customFields),
      }),
    ],
    onTransaction: ({ transaction }) => {
      setCaretPosition(transaction.selection.anchor);
    },
    onUpdate({ editor }) {
      if (editor) {
        const text = editor.getText();
        if (text[text.length - 1] === ":") {
          setOpenEmojiModal(true);
        } else {
          setOpenEmojiModal(false);
        }

        if (text[text.length - 1] === "/") {
          setOpenMessageTemplatesList(true);
        } else {
          setOpenMessageTemplatesList(false);
        }

        hasCustomFieldsWithoutDefaultValue(editor.getHTML());

        updateText(editor.getHTML());
      }
    },
    content: formatMessage(text || ''),
  });

  useEffect(() => {
    if (editor) {
      if (setFocus) {
        editor.commands.focus("end");
      }
      hasCustomFieldsWithoutDefaultValue(editor.getHTML());
    }
  }, [editor]);

  useEffect(() => {
    if (editor && showConfirmationText) {
      if (!editor.getText().includes(confirmationText.replace("<br/>", ""))) {
        editor.commands.insertContent("<br />" + confirmationText);
        editor.commands.focus("start");
      }
    }
  }, [showConfirmationText]);

  return (
    <>
      <Box sx={{ height: "100%", minHeight: "130px", position: 'relative' }}>
        <EditorContent
          readOnly={!!disabled}
          disabled={!!disabled}
          editor={editor}
        />
        {!disabled && (
          <Box>
            <Button
              sx={{
                minWidth: "10px",
                padding: 0,
                width: "10px",
                margin: 0,
                position: "absolute",
                right: messageTemplates === undefined ? "40px" : "70px",
                bottom: "5px",
              }}
              style={{
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={() => {
                editor?.commands.insertContentAt(caretPosition, " {");
              }}
            >
              <LocalOfferOutlinedIcon />
            </Button>
            <ClickAwayListener
              onClickAway={() => {
                handleMessageTemplatesListClose();
              }}
            >
              <>
                {messageTemplates && messageTemplates.length > 0 && (
                  <Button
                    ref={messageTemplateIconRef}
                    sx={{
                      minWidth: "10px",
                      padding: 0,
                      width: "10px",
                      margin: 0,
                      position: "absolute",
                      right: "40px",
                      bottom: "5px",
                    }}
                    style={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      editor?.commands.insertContentAt(caretPosition, " /");
                      editor?.commands.focus("end");
                    }}
                  >
                    <TextSnippetOutlinedIcon
                      sx={{
                        cursor:
                          messageTemplates && messageTemplates.length > 0
                            ? "pointer"
                            : "initial",
                      }}
                      color={
                        messageTemplates && messageTemplates.length > 0
                          ? "primary"
                          : "disabled"
                      }
                    />
                  </Button>
                )}
                {messageTemplates && messageTemplates.length === 0 && (
                  <Tooltip
                    placement="top"
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -10],
                            },
                          },
                        ],
                      },
                    }}
                    title="No templates available"
                  >
                    <Button
                      ref={messageTemplateIconRef}
                      sx={{
                        minWidth: "10px",
                        padding: 0,
                        width: "10px",
                        margin: 0,
                        position: "absolute",
                        right: "40px",
                        bottom: "5px",
                      }}
                      style={{
                        backgroundColor: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <TextSnippetOutlinedIcon
                        sx={{
                          cursor:
                            messageTemplates && messageTemplates.length > 0
                              ? "pointer"
                              : "initial",
                        }}
                        color={
                          messageTemplates && messageTemplates.length > 0
                            ? "primary"
                            : "disabled"
                        }
                      />
                    </Button>
                  </Tooltip>
                )}

                {messageTemplates && messageTemplates.length > 0 && (
                  <Menu
                    anchorEl={messageTemplateIconRef.current}
                    id="long-menu-message-templates"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    open={!!openMessageTemplatesList}
                    sx={{ borderRadius: "0" }}
                    onClose={handleMessageTemplatesListClose}
                    slotProps={{
                      paper: {
                        style: {
                          maxHeight: 48 * 4.5,
                        },
                      },
                    }}
                  >
                    {messageTemplates &&
                      messageTemplates.length > 0 &&
                      messageTemplates.map((m) => (
                        <MenuItem
                          sx={{
                            borderRadius: "5px",
                            margin: "5px",
                            height: "25px",
                            fontSize: "14px",
                          }}
                          key={m.name}
                          onClick={() => {
                            editor?.commands.insertContentAt(
                              { from: caretPosition - 1, to: caretPosition },
                              formatMessage(m.text)
                            );
                            editor?.commands.focus("end");
                            handleMessageTemplatesListClose();
                          }}
                        >
                          {m.name}
                        </MenuItem>
                      ))}
                  </Menu>
                )}
              </>
            </ClickAwayListener>
            <Button
              sx={{
                minWidth: "10px",
                padding: 0,
                width: "10px",
                margin: 0,
                position: "absolute",
                right: "10px",
                bottom: "5px",
              }}
              style={{
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenEmojiModal(true);
              }}
            >
              <InsertEmoticonIcon />
            </Button>
            <Stack direction="column">
              {openEmojiModal && (
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenEmojiModal(false);
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "20px",
                      right: "-350px",
                    }}
                  >
                    <EmojiPicker
                      autoFocusSearch={false}
                      style={{ zIndex: 999999 }}
                      onEmojiClick={(e: EmojiClickData) => {
                        const emoji = e.emoji;
                        editor?.commands.insertContentAt(
                          { from: caretPosition - 1, to: caretPosition },
                          emoji
                        );
                        editor?.commands.focus("end");
                        setOpenEmojiModal(false);
                      }}
                    />
                  </Box>
                </ClickAwayListener>
              )}
            </Stack>
          </Box>
        )}
      </Box>
      {hasFieldsWithoutDefaultValue && (
        <Box>
          <Typography
            sx={{
              fontStyle: "italic",
              fontSize: "12px",
              color: "red",
            }}
          >
            This message contains merge tags without default values. Only
            contacts with specified values for these merge tags will receive
            this message.
          </Typography>
        </Box>
      )}
    </>
  );
}
