import { useCallback } from "react";
import apiClient from "../axiosClient";
import {
  ArchiveAppointmentTypeApiParams,
  GetAppointmentTypesApiParams,
  GetAppointmentTypeApiParams,
  RestoreAppointmentTypeApiParams,
} from "./types";

export function useGetAppointmentTypesApi(): GetAppointmentTypesApiParams {
  return useCallback((params) => {
    return apiClient.get(`/appointments/types`, { params });
  }, []);
}

export function useGetAppointmentTypeApi(): GetAppointmentTypeApiParams {
  return useCallback(
    (appointmetTypeId, companyId) =>
      apiClient.get(`/appointments/types/${appointmetTypeId}`, {
        params: { companyId },
      }),
    []
  );
}

export function useArchiveAppointmentTypeApi(): ArchiveAppointmentTypeApiParams {
  return useCallback(
    (appointmentTypeId, companyId) =>
      apiClient.delete(`/appointments/types/${appointmentTypeId}`, {
        params: { companyId },
      }),
    []
  );
}

export function useRestoreAppointmentTypeApi(): RestoreAppointmentTypeApiParams {
  return useCallback(
    (appointmentTypeId: string, companyId: string) =>
      apiClient.patch(`/appointments/types/${appointmentTypeId}/restore`, {companyId: companyId}, {params: {companyId}}),
    []
  );
}

export function useCreateAppointmentTypeApi() {
  return useCallback(
    (
      name: string,
      color: string,
      companyId: string,
      location?: string,
      description?: string,
      notification?: string,
      assignedTo?: string,
    ) =>
      apiClient.post(
        `/appointments/types`,
        { name, color, location, description, notification, assignedTo },
        { params: { companyId } }
      ),
    []
  );
}

export function useUpdateAppointmentTypeApi() {
  return useCallback(
    (
      name: string,
      color: string,
      appointmentTypeId: string,
      companyId: string,
      location?: string,
      description?: string,
      notification?: string,
      assignedTo?: string,
    ) =>
      apiClient.put(
        `/appointments/types/${appointmentTypeId}`,
        { name, color, location, description, notification, assignedTo },
        { params: { companyId } }
      ),
    []
  );
}
