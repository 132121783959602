import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { ContentContainer, Container, PhoneContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  birthdayCampaignValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { Campaign, MessageTemplate } from "src/constants/types";
import * as Yup from "yup";
import {
  useGetCampaignApi,
  useCreateBirthdayCampaignApi,
  useUpdateBirthdayCampaignApi,
} from "src/api/campaigns";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { initialMessage, initialValues } from "./constants";
import MainWrapper from "src/components/MainWrapper/view";
import { useNavigate, useParams } from "react-router-dom";
import Phone from "src/components/PhoneUI/view";
import { Message, MessageFormValuesType } from "./types";
import { CampaignType, ExternalType } from "src/api/campaigns/types";
import MessageForm from "./MessageForm/view";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  Switch,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactRouterPrompt from "react-router-prompt";
import { DefaultCustomField } from "src/constants/types";
import { useGetDefaultCustomFieldsValuesApi } from "src/api/customFields";
import { useGetMessageTemplatesApi } from "src/api/messageTemplates";
import { GetMessageTemplatesParams } from "src/api/messageTemplates/types";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import BannedWordsModal from "src/components/BannedWordsModal/view";

const metadataLimit = 100000;
const messageTemplatesLimit = 10000;

export default function BirthdayCampaign() {
  const initialData = {
    ...initialValues,
    scheduledMessages: initialValues.scheduledMessages.concat([initialMessage]),
  };
  const [formValues, setFormValues] =
    useState<MessageFormValuesType>(initialData);
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
  const [metadata, setMetadata] = useState<DefaultCustomField[]>();
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>();
  const [expandedMessagePosition, setExpandedMessagePosition] = useState(0);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const getMetadata = useGetDefaultCustomFieldsValuesApi();
  const getMessageTemplates = useGetMessageTemplatesApi();
  const [isCampaingSaving, setIsCampaignSaving] = useState<boolean>(false);
  const createBirthdayCampaign = useCreateBirthdayCampaignApi();
  const updateBirthdayCampaign = useUpdateBirthdayCampaignApi();
  const getCampaign = useGetCampaignApi();
  const selectedCompanyId = useSelector(selectedCompany);
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const [formSnapshot, setFormSnapshot] = useState<string | null>(
    campaignId ? null : JSON.stringify(initialData)
  );
  const [showbannedWordsModal, setShowBannedWordsModal] =
      useState<boolean>(false);
  const [campaign, setCampaign] = useState<Campaign>();
  useHandleSelectedCompany();

  const handleClose = () => {
    navigate("/campaigns");
  };

  useEffect(() => {
    if (selectedCompanyId) {
      const params = {
        companyId: selectedCompanyId,
        limit: metadataLimit,
        offset: 0,
        types: "contact,company",
      };
      getMetadata(params)
        .then((data) => {
          setMetadata(data.data.items);
        })
        .catch((e) => {
          console.log(e);
        });
      const messageTemplatesParams: GetMessageTemplatesParams = {
        companyId: selectedCompanyId,
        offset: 0,
        limit: messageTemplatesLimit,
        status: "active",
      };
      getMessageTemplates(messageTemplatesParams)
        .then((data) => {
          setMessageTemplates(
            data.data.items.filter((t) => t.companyId === selectedCompanyId)
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedCompanyId]);

  const handleCampaignSave = async () => {
    const isValid = await validate(formValues);

    function saveCampaign() {
      setIsCampaignSaving(true);
      const { name, scheduledMessages, isOptOutEnabled, optOutText } =
        formValues;
      const params = {
        campaignType: "annual" as CampaignType,
        externalType: "birthday" as ExternalType,
        name,
        scheduledMessages: scheduledMessages
          .filter((m) => m.text)
          .map((item) => {
            delete item.campaignId;
            delete item.dateSend;
            delete item.createdBy;
            delete item.updatedBy;
            delete item.dateCreated;
            delete item.dateUpdated;
            delete item.isCaptureContact;
            delete item.linksWithMimeType;
            delete item.time;
            delete item.dataCapture;
            delete item.attachCalendar;
            item.links = item.type === "mms" ? item.links : [];
            item.subject = item.type === "mms" ? item.subject : "";
            item.text = formatMessage(item.text);
            return item;
          }),
        optOutText: isOptOutEnabled && optOutText ? optOutText : null,
      };
      if (campaignId) {
        selectedCompanyId &&
          updateBirthdayCampaign(params, campaignId, selectedCompanyId)
            .then(() => {
              handleClose();
            })
            .catch((e) => {
              if (e.code === 422) {
                setIsCampaignSaving(false);
                setShowBannedWordsModal(true);
              }
              if (e.error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
            });
      } else {
        selectedCompanyId &&
          createBirthdayCampaign(params, selectedCompanyId)
            .then(() => {
              handleClose();
            })
            .catch((e) => {
              if (e.code === 422) {
                setIsCampaignSaving(false);
                setShowBannedWordsModal(true);
              }
              if (e.error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
            });
      }
    }

    if (isValid) {
      saveCampaign();
    }
  };

  useEffect(() => {
    if (campaignId && selectedCompanyId) {
      getCampaign({ companyId: selectedCompanyId }, campaignId).then(
        ({ data }) => {
          setIsCampaignLoaded(true);
          setCampaign(data);
          const { name, dateScheduled, scheduledMessages, optOutText } = data;
          const formData = {
            name,
            dateScheduled,
            scheduledMessages:
              scheduledMessages.length > 1
                ? scheduledMessages.sort((a, b) => a.position - b.position)
                : scheduledMessages,
            isOptOutEnabled: !!optOutText,
            optOutText: optOutText || "",
          };
          setFormValues(formData);
          setFormSnapshot(JSON.stringify(formData));
        }
      );
    } else {
      setFormSnapshot(JSON.stringify(formValues));
      setIsCampaignLoaded(true);
    }
  }, [campaignId, selectedCompanyId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      dateScheduled: formValues.dateScheduled ?? null,
    });
  };

  const updateMessage = (message: Message) => {
    const scheduledMessages = [...formValues.scheduledMessages];
    const messageIndex = scheduledMessages.findIndex(
      (m) => m.position === message.position
    );
    scheduledMessages[messageIndex] = message;
    setFormValues({ ...formValues, scheduledMessages: scheduledMessages });
  };

  const addMessage = (
    position: number,
    previousMessage: Message | undefined = undefined
  ): void => {
    const message = formValues.scheduledMessages.find(
      (m) => m.position === position
    );
    if (!message) {
      return;
    }
    let rearrangedMessages = formValues.scheduledMessages.filter(
      (m) => m.position <= position
    );

    const newMessage: Message = {
      ...initialMessage,
      text: "",
      position: message.position + 1,
      scheduledType:
        previousMessage &&
        (previousMessage.scheduledType === "reply" ||
          previousMessage?.dataCapture)
          ? "reply"
          : "delay",
    };

    rearrangedMessages = rearrangedMessages
      .concat([newMessage])
      .concat(
        formValues.scheduledMessages
          .filter((m) => m.position > position)
          .map((i) => ({ ...i, position: i.position + 1 }))
      );
    setFormValues({
      ...formValues,
      scheduledMessages: rearrangedMessages,
    });
    setExpandedMessagePosition(newMessage.position);
  };

  const deleteMessage = (position: number): void => {
    const message = formValues.scheduledMessages.find(
      (m) => m.position === position
    );
    if (!message) {
      return;
    }
    let rearrangedMessages = formValues.scheduledMessages.filter(
      (m) => m.position < position
    );
    rearrangedMessages = rearrangedMessages.concat(
      formValues.scheduledMessages
        .filter((m) => m.position > position)
        .map((i) => ({ ...i, position: i.position - 1 }))
    );
    setFormValues({
      ...formValues,
      scheduledMessages: rearrangedMessages,
    });
  };

  const isDirty = (message: Message): boolean => {
    if (
      (message.isCaptureContact && !message.dataCapture) ||
      (message.scheduledType === "delay" &&
        message.delay &&
        message.delay.length < 2 &&
        message)
    ) {
      return false;
    }

    return !!message.text || message.links.length > 0;
  };

  if (!isCampaignLoaded) {
    return null;
  }

  const formatMessage = (html: string) => {
    const result = convertHtmlToText(html);
    return result.length ? result : "Edit Message";
  };

  const convertHtmlToText = (html: string) => {
    const container = document.createElement("div");
    container.innerHTML = html
      .replaceAll("<br>", "\n")
      .replaceAll("<br/>", "\n");
    const customFields = container.querySelectorAll("span.mention");
    customFields.forEach((customField) => {
      customField.replaceWith(
        "{{" + customField.getAttribute("data-id") + "}}"
      );
    });
    const result = container.innerText;
    return result;
  };

  const message =
    formValues.scheduledMessages && formValues.scheduledMessages.length > 0
      ? formValues.scheduledMessages[0]
      : { ...initialMessage };

  const isBtnsDisabled =
    !formValues.name ||
    !convertHtmlToText(message?.text) ||
    formValues.scheduledMessages.some(
      (m) => !m.delay || m.delay.includes("NaN")
    );

  const previewMessages = formValues.scheduledMessages.map((m) => ({
    isUser: false,
    text: m.text,
    links: m.links,
    linksWithMimeType: m.linksWithMimeType,
    subject: m.subject,
    position: m.position,
  }));

  const validate = async (values: typeof formValues) => {
    try {
      await birthdayCampaignValidationSchema.validate(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.checked,
      ...(event.target.checked ? { optOutText: "Reply STOP to opt-out" } : {}),
    });
  };

  if (!isCampaignLoaded) {
    return null;
  }

  const isOnlyReadable =
    !!campaignId &&
    campaign &&
    campaign.type === "message" &&
    (!!campaign.dateSend ||
      (!!campaign.dateScheduled && dayjs(campaign.dateScheduled) < dayjs()));

  return (
    <MainWrapper>
      <ReactRouterPrompt
        when={!isCampaingSaving && formSnapshot !== JSON.stringify(formValues)}
      >
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <Dialog open={true}>
              <DialogTitle>You have unsaved changes!</DialogTitle>
              <DialogContent>
                Do you want to continue without saving?
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  No
                </Button>
                <Button onClick={onConfirm} color="secondary">
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      </ReactRouterPrompt>
      <DialogTitle
        sx={{ m: 0, p: 2 }}
        id="customized-dialog-title"
        style={{ padding: 0 }}
      >
        {isOnlyReadable ? "" : campaignId ? "Update " : "Create "}
        Birthday Campaign
      </DialogTitle>
      <Container>
        <ContentContainer>
          <TextField
            sx={{ marginBottom: "10px" }}
            id="outlined-basic"
            label="Campaign Name"
            disabled={isOnlyReadable}
            name="name"
            variant="outlined"
            value={formValues.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors?.name || ""}
          />

          {formValues.scheduledMessages.map((m, i) => (
            <div key={i} style={{ textAlign: "center" }}>
              <Accordion
                onChange={(e, expanded) => {
                  e.currentTarget.scrollIntoView({ behavior: "smooth" });
                  setExpandedMessagePosition(expanded ? m.position : 0);
                }}
                expanded={
                  m.position === expandedMessagePosition ||
                  formValues.scheduledMessages.length === 1
                }
              >
                <AccordionSummary
                  style={{ overflow: "hidden" }}
                  sx={{
                    ".MuiAccordionSummary-content": {
                      overflow: "hidden",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "0 !important",
                      marginTop: "0 !important",
                    },
                  }}
                  expandIcon={
                    formValues.scheduledMessages.length > 1 ? (
                      <ArrowDropDownIcon />
                    ) : null
                  }
                  id="panel-header"
                  aria-controls="panel-content"
                >
                  <Box
                    style={{
                      overflow: "hidden",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "left",
                        textWrap: "nowrap",
                        marginRight: "5px",
                        width: "90%",
                      }}
                    >
                      {formatMessage(m.text)}
                    </div>
                    <div
                      style={{
                        marginTop: "5px",
                        color: "#b2b2b2",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      {m.position > 1 && (
                        <IconButton
                          sx={{
                            "&:hover": {
                              borderRadius: "4px",
                              backgroundColor: "#2545970a",
                            },
                          }}
                          size="small"
                          color="primary"
                          style={{
                            marginTop: "-25px",
                            borderRadius: "4px",
                            width: "40px",
                          }}
                          disabled={isOnlyReadable}
                          onClick={(e) => {
                            e.stopPropagation();
                            openDialog({
                              title: "Delete",
                              content:
                                "Are you sure you want to delete this message?",
                              onConfirm: () => {
                                deleteMessage(m.position);
                              },
                            });
                          }}
                        >
                          <DeleteIcon style={{ transform: "scale(0.8)" }} />
                        </IconButton>
                      )}
                    </div>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ textAlign: "left", paddingTop: "0" }}>
                  <MessageForm
                    disabled={isOnlyReadable}
                    key={m.id}
                    metadata={metadata}
                    messageTemplates={messageTemplates}
                    message={m}
                    updateMessage={updateMessage}
                  />
                </AccordionDetails>
              </Accordion>
              <IconButton
                sx={{
                  "&:hover": {
                    borderRadius: "4px",
                    backgroundColor: "#2545970a",
                  },
                }}
                color="primary"
                style={{ borderRadius: "4px" }}
                disabled={!isDirty(m) || isOnlyReadable || isBtnsDisabled}
                onClick={() => addMessage(m.position, m)}
              >
                <AddCircleOutlineIcon style={{ transform: "scale(0.8)" }} />
              </IconButton>
            </div>
          ))}
          <FormControlLabel
            control={
              <Switch
                disabled={isOnlyReadable}
                checked={formValues.isOptOutEnabled}
                onChange={handleToggleChange}
                name="isOptOutEnabled"
              />
            }
            label="Include opt-out message"
            labelPlacement="start"
            style={{ marginLeft: 0, justifyContent: "flex-end" }}
          />
          {formValues.isOptOutEnabled && (
            <TextField
              id="outlined-basic"
              label="Opt-out message"
              name="optOutText"
              variant="outlined"
              style={{ margin: "20px 0px" }}
              value={formValues.optOutText}
              onChange={handleChange}
            />
          )}
          <Button
            disabled={isBtnsDisabled}
            variant="contained"
            style={{ width: "150px", marginTop: "20px" }}
            onClick={handleCampaignSave}
          >
            {campaignId ? "Update " : "Create "}
          </Button>
        </ContentContainer>
        <PhoneContainer>
          <Phone
            metadata={metadata}
            optOutText={formValues.isOptOutEnabled ? formValues.optOutText : ""}
            messages={previewMessages.sort((a, b) => a.position - b.position)}
          />
        </PhoneContainer>
      </Container>
      <ConfirmationDialog />
      <BannedWordsModal
              isOpen={showbannedWordsModal}
              onConfirm={() => setShowBannedWordsModal(false)}
            />
    </MainWrapper>
  );
}
