/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Box } from "@mui/material";

import MainWrapper from "src/components/MainWrapper/view";

import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import "react-big-calendar/lib/css/react-big-calendar.css";
import AppointmentCalendar from "./AppointmentCalendar";
import { PageHeader } from "../Groups/styles";

const Appointments = () => {
  useHandleSelectedCompany(); 
  return (
    <MainWrapper>
      <PageHeader>Appointments</PageHeader>
      <Box sx={{ height: "75vh" }}>
        <AppointmentCalendar />
      </Box>
    </MainWrapper>
  );
};

export default Appointments;
