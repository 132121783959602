import React from "react";
import { useSelector } from "react-redux";
import { selectProfile } from "src/store/profile/profileSlice";
import { Box, Stack, Typography } from "@mui/material";
import BhiTable, { bhiTableNames } from "src/components/Table/view";
import { useHandleSelectedCompany } from "src/hooks/useHandleSelectedCompany";
import { AppointmentType } from "src/constants/types";
import { GridColDef } from "@mui/x-data-grid";
import { selectedCompany } from "src/store/company/companySlice";
import {
  useArchiveAppointmentTypeApi,
  useRestoreAppointmentTypeApi,
  useGetAppointmentTypeApi,
  useGetAppointmentTypesApi,
} from "src/api/appointmentTypes";
import { getRandomHexColor } from "./helpers";

const AppointmentTypes = () => {
  const { profile } = useSelector(selectProfile);
  const selectedCompanyId = useSelector(selectedCompany);
  const getAppointmentTypes = useGetAppointmentTypesApi();
  const getAppointmentType = useGetAppointmentTypeApi();
  const archiveAppointmentType = useArchiveAppointmentTypeApi();
  const restoreAppointmentType = useRestoreAppointmentTypeApi();

  useHandleSelectedCompany();

  const initialAppointmentType: AppointmentType = {
    id: "",
    name: "",
    color: getRandomHexColor(),
    companyId: selectedCompanyId!,
    description: "",
    location: "",
    notification: "",
    campaignId: undefined,
    campaignName: undefined,
  };

  const formatColumns = () => {
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Appointment Type",
        flex: 1,
        sortable: true,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="column" height="100%" justifyContent="center">
              <Typography
                sx={{
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue && (
                  <Box>
                    {row.formattedValue}{" "}
                    {row.row.status === "active" ? (
                      ""
                    ) : (
                      <Box sx={{ display: "inline-block", fontWeight: "bold" }}>
                        (Archive)
                      </Box>
                    )}
                  </Box>
                )}
              </Typography>
            </Stack>
          );
        },
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        sortable: false,
        editable: false,
      },
      {
        field: "location",
        headerName: "Location",
        flex: 1,
        sortable: false,
        editable: false,
      },
      {
        field: "notification",
        headerName: "Notification",
        flex: 1,
        sortable: false,
        editable: false,
        renderCell: (row) => {
          return <>{row.formattedValue?.replace("-", "")}</>;
        },
      },
      {
        field: "color",
        headerName: "Color",
        sortable: false,
        flex: 1,
        editable: false,
        renderCell: (row) => {
          return (
            <Stack direction="column" height="100%" justifyContent="center">
              <Typography
                sx={{
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {row.formattedValue && (
                  <Box
                    sx={{
                      display: "inline-block",
                      borderRadius: "5px",
                      padding: "8px",
                      backgroundColor: row.formattedValue,
                    }}
                  >
                    {row.formattedValue}
                  </Box>
                )}
              </Typography>
            </Stack>
          );
        },
      },
    ];

    return columns;
  };

  if (!profile) {
    return null;
  }

  return (
    <>
      <BhiTable<AppointmentType>
        selectedCompanyId={selectedCompanyId}
        tableName={bhiTableNames.appointmentTypes}
        formatColumns={formatColumns}
        fetchData={getAppointmentTypes}
        getItem={getAppointmentType}
        deleteItem={archiveAppointmentType}
        restoreItem={restoreAppointmentType}
        initialData={initialAppointmentType}
        allowActions={true}
        pageSize={100}
      />
    </>
  );
};

export default AppointmentTypes;
