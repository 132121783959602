import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  InboxContact,
  InboxContacts,
  User,
} from "src/constants/types";
import Contact from "./Contact";
import { NotePencil, MagnifyingGlass } from "phosphor-react";
import styled from "styled-components";
import AwayMessageSwitcher from "./AwayMessageSwitcher";

export const PageHeader = styled.div`
  font-size: 23px;
  color: #254597;
`;

type InboxLeftSectionProps = {
  isShowAll: boolean;
  onSelectContact: React.Dispatch<React.SetStateAction<string | null>>;
  onlyMyConversations: boolean;
  setOnlyMyConversations: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedContact: React.Dispatch<
    React.SetStateAction<InboxContact | undefined>
  >;
  toggleShowAll: (value: boolean) => void;
  onSearchContact: (value: string) => void;
  contacts: InboxContacts | null;
  selectedContact: string | null;
  lastSelectedChatMessageDate: string | undefined;
  isContactsLoading: boolean;
  handleNewChatClick: () => void;
  fetchMoreData: () => void;
  totalContactsCount: number;
  hasMoreContacts: boolean;
  search: string;
  users: User[];
};

function InboxLeftSection({
  onSelectContact,
  onSearchContact,
  toggleShowAll,
  selectedContact,
  contacts,
  lastSelectedChatMessageDate,
  isContactsLoading,
  handleNewChatClick,
  hasMoreContacts,
  fetchMoreData,
  search,
  users,
  setOnlyMyConversations,
  setSelectedContact,
}: InboxLeftSectionProps) {
  const [term, setTerm] = useState(search);
  const contactsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTerm(search);
  }, [search]);

  useEffect(() => {
    const contactsList = contactsListRef.current;
    if (contactsList) {
      contactsList.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        contactsList.removeEventListener("scroll", onScroll);
      };
    }
  }, [contactsListRef, contacts?.items, hasMoreContacts]);

  const handleSelectChange = ({ target }: SelectChangeEvent<string>) => {
    switch (target.value) {
      case "all": {
        setOnlyMyConversations(false);
        toggleShowAll(true);
        break;
      }
      case "unread": {
        setOnlyMyConversations(false);
        toggleShowAll(false);
        break;
      }
      case "myconversations": {
        setOnlyMyConversations(true);
        toggleShowAll(true);
        break;
      }
    }
    setSelectedContact(undefined);
  };

  const onScroll = () => {
    if (contactsListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contactsListRef.current;
      const isNearBottom =
        Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
      if (isNearBottom) {
        if (hasMoreContacts) {
          fetchMoreData();
        }
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          minHeight: "100%",
          height: "100%",
          width: 320,
          overflowX: "hidden",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack spacing={2} sx={{ height: "85vh", maxHeight: "85vh" }}>
          <Stack
            padding={2}
            paddingBottom={0}
            alignItems={"center"}
            justifyContent="space-between"
            direction="row"
          >
            <PageHeader>Inbox</PageHeader>
            <IconButton
              onClick={() => {
                handleNewChatClick();
              }}
            >
              <NotePencil />
            </IconButton>
          </Stack>
          <Box sx={{ paddingLeft: "15px", paddingRight: "15px", marginTop: '5 !important' }}>
            <AwayMessageSwitcher />
          </Box>
          <Stack p={2} paddingTop={0}>
            <Select
              size="small"
              labelId="bhi-simple-select-label"
              id="bhi-simple-select"
              defaultValue="all"
              onChange={handleSelectChange}
              sx={{ minWidth: "150px", marginBottom: "15px" }}
            >
              <MenuItem value={"all"} key={"all"}>
                All
              </MenuItem>
              <MenuItem value={"unread"} key={"unread"}>
                Unread Only
              </MenuItem>
              <MenuItem value={"myconversations"} key={"myconversations"}>
                Only My Conversations
              </MenuItem>
            </Select>
            <FormControl
              sx={{ width: "100%", borderColor: "rgba(0, 0, 0, 0.25)" }}
              size="small"
              variant="outlined"
            >
              <OutlinedInput
                value={term}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setTerm(value);
                  onSearchContact(value);
                  onSelectContact(null);
                }}
                placeholder="Search Contacts..."
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <MagnifyingGlass />
                  </InputAdornment>
                }
                aria-describedby="outlined-search-helper-text"
                inputProps={{
                  "aria-label": "search",
                }}
              />
            </FormControl>
          </Stack>
          {isContactsLoading && (
            <Stack direction="row" width="100%" height="100%">
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size="24px" color="secondary" />
              </Box>
            </Stack>
          )}
          {!isContactsLoading &&
            contacts &&
            contacts.items &&
            !contacts.items.length && (
              <Box
                width="100%"
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                No Results
              </Box>
            )}
          {!isContactsLoading &&
            contacts &&
            contacts.items &&
            contacts.items.length > 0 && (
              <Stack
                ref={contactsListRef}
                sx={{
                  marginTop: '0 !important',
                  flexGrow: 1,
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "100%",
                }}
              >
                <Stack>
                  {!contacts.items!.length && (
                    <Box sx={{ color: "#ccc" }}>No Results</Box>
                  )}
                  {[...contacts.items!].map((el) => {
                    return (
                      <Fragment key={el.contact.id}>
                        <Contact
                          users={users}
                          contact={el}
                          selectedContact={selectedContact}
                          lastSelectedChatMessageDate={
                            lastSelectedChatMessageDate
                          }
                          onSelectChat={onSelectContact}
                        />
                        <Divider />
                      </Fragment>
                    );
                  })}
                </Stack>
              </Stack>
            )}
        </Stack>
      </Box>
    </>
  );
}

export default InboxLeftSection;
