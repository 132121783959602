import React, { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  addAppointmentValidationSchema,
  ValidationErrors,
} from "src/constants/validations";
import { Appointment, AppointmentType, Contact } from "src/constants/types";
import * as Yup from "yup";
import {
  Stack,
  SelectChangeEvent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { selectProfile } from "src/store/profile/profileSlice";
import {
  useAssignAppointmentContactsApi,
  useCreateAppointmentApi,
  useDeleteAppointmentApi,
  useRemoveAppontmentContactsApi,
  useUpdateAppointmentApi,
} from "src/api/appointments";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import { useCreateContactsApi, useGetContactsApi } from "src/api/contacts";
import SelectWithSearch from "src/components/SelectWithSearch/view";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    overflow: "visible",
    minWidth: "900px",
  },
  "& .MuiDialogContent-root": {
    overflow: "visible",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateAppointmentTypeModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialAppointment: Appointment;
  appointment: Appointment | null;
  refreshAppointments: () => void;
  appointmentTypes: AppointmentType[];
  slotStartDate: string | undefined;
  slotEndDate: string | undefined;
};

export default function CreateAppointmentModal({
  show,
  setShowModal,
  initialAppointment,
  appointment,
  appointmentTypes,
  refreshAppointments,
  slotStartDate,
  slotEndDate,
}: CreateAppointmentTypeModalType) {
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [contacts, setContacts] = useState<Contact[]>();
  const [contactIds, setContactIds] = React.useState<string[]>([]);
  const [existingContactIds, setExistingContactIds] = React.useState<string[]>(
    []
  );
  const [locationPlaceholder, setLocationPlaceholder] = useState<string>();
  const [descriptionPlaceholder, setDescriptionPlaceholder] =
    useState<string>();
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [location, setLocation] = useState<string | undefined>(undefined);

  const { openDialog, ConfirmationDialog } = useConfirmationDialog();
  const selectedCompanyId = useSelector(selectedCompany);
  const createAppointment = useCreateAppointmentApi();
  const updateAppointment = useUpdateAppointmentApi();
  const deleteAppointment = useDeleteAppointmentApi();
  const assignContact = useAssignAppointmentContactsApi();
  const removeContact = useRemoveAppontmentContactsApi();
  const getContacts = useGetContactsApi();
  const createContacts = useCreateContactsApi();
  const { profile } = useSelector(selectProfile);
  const [formValues, setFormValues] = useState<Appointment>(
    appointment ?? {
      ...initialAppointment,
      startDate: slotStartDate,
      endDate: slotEndDate,
      appointmentType:
        appointmentTypes.length === 1 ? appointmentTypes[0] : undefined,
    }
  );

  const isValidPhoneNumber = (phone: string): boolean => {
    let trimmedPhone = phone.replace("Add ", "").trim();
    if (!trimmedPhone) {
      return false;
    }

    if (trimmedPhone[0] === "1") {
      trimmedPhone = trimmedPhone.substring(1);
    }

    if (trimmedPhone.startsWith("+1")) {
      trimmedPhone = trimmedPhone.replace("+1", "");
    }
    const phonenumberRegex = /^\d{10}$/;
    return new RegExp(phonenumberRegex).test(trimmedPhone.toString());
  };

  const handleAddContact = (phoneNumber: string): void => {
    if (isValidPhoneNumber("+1" + phoneNumber.trim().replace("+1", ""))) {
      const newContact = {
        email: "",
        firstName: "",
        lastName: "",
        birthdate: "",
        timeZone: "",
        phoneNumber: phoneNumber,
      };
      createContacts([newContact], selectedCompanyId!)
        .then(() => {
          getContacts({
            companyId: selectedCompanyId!,
            limit: 100000,
            offset: 0,
          })
            .then(({ data }) => {
              setContacts(data.items);
              console.log(phoneNumber);
              const newContact = data.items.find(
                (k) => k.phoneNumber === "+1" + phoneNumber
              );
              if (newContact) {
                const selectedContacts = [...contactIds];
                selectedContacts.push(newContact.id);
                setContactIds(selectedContacts);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    items: { text: string; value: string }[]
  ) => {
    const contactIds = items?.map(({ value }) => value) || [];

    setContactIds(contactIds);
  };

  const refreshContacts = () => {
    getContacts({
      companyId: selectedCompanyId!,
      limit: 100000,
      offset: 0,
    })
      .then(({ data }) => {
        setContacts(data.items);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    refreshContacts();
  }, []);

  useEffect(() => {
    refreshContacts();
  }, [contactIds]);

  useEffect(() => {
    const description = formValues.appointmentType?.description;
    const location = formValues.appointmentType?.location;
    if (description) {
      setDescriptionPlaceholder(description);
    }
    if (location) {
      setLocationPlaceholder(location);
    }
  }, [formValues.appointmentType]);

  useEffect(() => {
    setFormValues(formValues);
    if (appointment && appointment.appointmentContacts) {
      setExistingContactIds(
        appointment?.appointmentContacts?.map((c) => c.contact.id)
      );
      setContactIds(appointment?.appointmentContacts?.map((c) => c.contact.id));
      if (appointment.appointmentType && appointment.id) {
        setLocation(
          appointment.location
            ? appointment.location
            : appointment.appointmentType.location
        );
        setDescription(
          appointment.description
            ? appointment.description
            : appointment.appointmentType.description
        );
      }
    }
    return () => {
      setFormValues(initialAppointment);
    };
  }, [appointment]);

  const handleClose = () => {
    setShowModal(false);
    setFormValues(initialAppointment);
    setContactIds([]);
    setErrors({});
  };

  const handleAppointmentDelete = (id: string) => {
    deleteAppointment(id, selectedCompanyId!).then(() => {
      handleClose();
      refreshAppointments();
    });
  };

  const getAppointmentStatus = (id: string) => {
    const appointmentContact = appointment?.appointmentContacts?.find(
      (a) => a.contact.id == id
    );
    if (appointmentContact) {
      return appointmentContact.confirmationStatus;
    }
    return "";
  };

  const formatContactText = (
    firstName: string,
    lastName: string,
    phone: string
  ): string => {
    return firstName && lastName
      ? firstName + " " + lastName
      : firstName && phone
      ? firstName + " - " + `${phone}`
      : `"${phone}"`;
  };

  const handleContactsSave = (
    appointmentId: string,
    isNewAppointment: boolean
  ) => {
    const addedIds = isNewAppointment
      ? contactIds
      : contactIds.filter((id) => !existingContactIds.includes(id)) ?? [];
    const removedIds = isNewAppointment
      ? []
      : appointment?.appointmentContacts
          ?.filter((c) => !contactIds.includes(c.contact.id))
          .map((c) => c.id) ?? [];

    const addPromises = addedIds.map((id) =>
      assignContact(id, appointmentId, selectedCompanyId!)
    );

    const removePromises = removedIds.map((id) =>
      removeContact(id, appointmentId, selectedCompanyId!)
    );

    return Promise.all([addPromises, removePromises]);
  };

  const handleAppointmentSave = () => {
    const isValid = validate(formValues);
    if (isValid && selectedCompanyId) {
      if (formValues.id) {
        updateAppointment(
          formValues.id,
          formValues.name,
          formValues.appointmentType!.id,
          formValues.startDate!,
          formValues.endDate!,
          formValues.companyId,
          description,
          location
        )
          .then(() => {
            handleContactsSave(formValues.id!, false).then(() => {
              refreshAppointments();
              handleClose();
            });
          })
          .catch(({ error }) => {
            console.log(error);
          });
      } else {
        createAppointment(
          formValues.name,
          formValues.appointmentType!.id,
          formValues.startDate!,
          formValues.endDate!,
          formValues.companyId,
          description,
          location
        )
          .then((response) => {
            handleContactsSave(response.data.id!, true)
              .then(() => {
                handleClose();
                refreshAppointments();
              })
              .catch(({ error }) => {
                console.log(error);
              });
          })
          .catch((error) => {
            if (error.error === "conflict") {
              setErrors({
                name: "Appointment with this name already exists",
              });
            }
            if (error.message && error.message.includes("duplicate key")) {
              setErrors({
                name: "Appointment with this name already exists",
              });
            }
          });
      }
    }
  };

  const handleChangeAppointmentType = ({
    target,
  }: SelectChangeEvent<string>) => {
    const type = appointmentTypes?.find((a) => a.id === target.value);
    if (type) {
      setFormValues({
        ...formValues,
        appointmentType: type,
      });
    }
  };

  const handleApponmentDescriptionOrLocationChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "description":
        setDescription(value ? value : undefined);
        setDescriptionPlaceholder("");
        break;
      case "location":
        setLocation(value ? value : undefined);
        setLocationPlaceholder("");
        break;
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const isValidForm = (values: typeof formValues) => {
    try {
      addAppointmentValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      return true;
    } catch {
      return false;
    }
  };

  const validate = (values: typeof formValues) => {
    try {
      addAppointmentValidationSchema.validateSync(values, {
        abortEarly: false,
      });
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title1"
        open={show}
        fullWidth={true}
        PaperProps={{ overflowY: "visible" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {profile?.type === "user" ? "" : formValues.id ? " " : " "}
          {""}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            transform: "scale(0.7)",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            <TextField
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: "5px" }}
              autoComplete="off"
              id="outlined-basic"
              label={"Appointment Title"}
              name="name"
              variant="outlined"
              value={formValues.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors?.name || ""}
            />

            <Typography
              sx={{
                fontSize: ".8rem",
                marginBottom: "10px",
                textAlign: "right",
                fontStyle: "italic",
              }}
            >
              {formValues.appointmentType?.campaignId &&
                formValues.appointmentType?.campaignName && (
                  <>
                    Campaign:{" "}
                    <Link
                      to={`/campaigns/appointment-reminder/${formValues.appointmentType?.campaignId}`}
                    >
                      {formValues.appointmentType.campaignName}
                    </Link>
                  </>
                )}
              {!formValues.appointmentType?.campaignId && (
                <Link to={"/campaigns/appointment-reminder/"} state={{appoitmentTypeId: formValues.appointmentType?.id}}>
                  Create Campaign
                </Link>
              )}
            </Typography>

            <Stack direction="row" gap="15px" sx={{ marginTop: "10px" }}>
              <Stack direction="column">
                <FormControl>
                  <InputLabel>Appointment Type</InputLabel>
                  <Select
                    sx={{ marginBottom: "10px" }}
                    label="Appointment Type"
                    MenuProps={{ disableScrollLock: true }}
                    value={formValues.appointmentType?.id}
                    onChange={handleChangeAppointmentType}
                    name="fieldName"
                  >
                    {appointmentTypes
                      .sort((a, b) =>
                        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
                      )
                      .map((c) => (
                        <MenuItem
                          value={c.id}
                          key={c.id}
                          disabled={c.status !== "active"}
                        >
                          {c.name} {c.status === "active" ? "" : "(archived)"}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Stack
                  sx={{ marginBottom: "15px", gap: "5px" }}
                  direction="row"
                  justifyContent="space-between"
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        closeOnSelect={false}
                        label="Start Date"
                        value={
                          formValues.startDate
                            ? dayjs(formValues.startDate)
                            : undefined
                        }
                        onChange={(value) =>
                          setFormValues({
                            ...formValues,
                            startDate:
                              value?.toISOString() ?? dayjs().toISOString(),
                          })
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        closeOnSelect={false}
                        label="End Date"
                        value={
                          formValues.endDate
                            ? dayjs(formValues.endDate)
                            : undefined
                        }
                        minDateTime={
                          formValues.startDate
                            ? dayjs(formValues.startDate).add(5, "minutes")
                            : dayjs(new Date()).add(5, "minutes")
                        }
                        onChange={(value) =>
                          setFormValues({
                            ...formValues,
                            endDate:
                              value?.toISOString() ?? dayjs().toISOString(),
                          })
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Stack>
                <TextField
                  disabled={!formValues.appointmentType?.id}
                  sx={{ marginBottom: "10px" }}
                  autoComplete="off"
                  id="outlined-basic"
                  InputLabelProps={{ shrink: true }}
                  label={"Location"}
                  name="location"
                  variant="outlined"
                  placeholder={locationPlaceholder}
                  value={location}
                  onChange={handleApponmentDescriptionOrLocationChange}
                  error={!!errors.location}
                  helperText={errors?.location || ""}
                />
                <TextField
                  disabled={!formValues.appointmentType?.id}
                  sx={{ marginTop: "10px" }}
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  multiline
                  rows="3"
                  label={"Description"}
                  name="description"
                  variant="outlined"
                  placeholder={descriptionPlaceholder}
                  value={description}
                  onChange={handleApponmentDescriptionOrLocationChange}
                  error={!!errors.description}
                  helperText={errors?.description || ""}
                />
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Box>
                <SelectWithSearch
                  disableClearable={true}
                  label=""
                  showResultsUnderSearchTextBox={true}
                  placeholder="Add Contact(s)"
                  options={
                    contacts
                      ?.sort((a, b) =>
                        String(a.phoneNumber).localeCompare(b.phoneNumber)
                      )
                      .map(({ firstName, lastName, phoneNumber, id }) => ({
                        text: formatContactText(
                          firstName,
                          lastName,
                          phoneNumber
                        ),
                        value: id,
                        status: getAppointmentStatus(id),
                      })) || []
                  }
                  values={contactIds}
                  handleSelectChange={handleSelectChange}
                  handleAddItem={handleAddContact}
                  isValidNewItem={isValidPhoneNumber}
                  entity="Contact"
                  style={{
                    width: "100%",
                    minWidth: "277px",
                    margin: "0 0 20px 0",
                    overflow: "auto",
                  }}
                />
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                {appointment && appointment.id && (
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    sx={{
                      width: "150px",
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      openDialog({
                        title: `Delete appointment`,
                        content: `Are you sure you want to delete this appointment?`,
                        onConfirm: () => {
                          handleAppointmentDelete(appointment.id!);
                        },
                      });
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    width: "150px",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  disabled={!isValidForm(formValues)}
                  variant="contained"
                  sx={{
                    width: "150px",
                    marginTop: "20px",
                  }}
                  onClick={handleAppointmentSave}
                >
                  {formValues.id ? "Save" : "Create"}
                </Button>
              </Box>
            </Stack>
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
      <ConfirmationDialog />
    </Fragment>
  );
}
