import React, { useState, useEffect, useRef } from "react";
import { HeaderContainer } from "./styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { columns, itemsPerPage } from "./costants";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { CustomField, DefaultCustomField } from "src/constants/types";
import CreateCustomFieldModal from "./CreateCustomFieldModal/view";
import useConfirmationDialog from "src/components/ConfirmationDialog/view";
import {
  useDeleteCustomFieldApi,
  useDeleteCompanyCustomFieldApi,
  useGetDefaultCustomFieldsValuesApi,
} from "src/api/customFields";
import SearchInput from "../../../../components/SearchInput/view";
import SortIcon from "../../../../components/SortIcon/view";
import { GetCustomFieldsParams } from "../../../../api/customFields/types";

type CustomFieldProps = {
  showButtons: boolean;
  isCompanyFields: boolean;
  profileRole: string;
};

function CustomFields({ showButtons, isCompanyFields, profileRole }: CustomFieldProps) {
  const [page, setPage] = useState(0);
  const tableRef = useRef<HTMLTableElement>(null);
  const [sortField, setSortField] = useState<string>("name");
  const [sortDir, setSortDir] = useState<"asc" | "desc">("asc");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showCreateCustomFieldModal, setCreateCustomFieldModal] =
    useState(false);
  const selectedCompanyId: string | undefined = useSelector(selectedCompany);
  const [defaultFields, setDefaultFields] = useState<{
    count: number;
    items: DefaultCustomField[];
  }>({ count: 0, items: [] });
  const [editableField, setEditableField] = useState<CustomField | undefined>();
  const getDefaultValues = useGetDefaultCustomFieldsValuesApi();
  const deleteCustomField = isCompanyFields
    ? useDeleteCompanyCustomFieldApi()
    : useDeleteCustomFieldApi();
  const { openDialog, ConfirmationDialog } = useConfirmationDialog();

  const isButtonsDisabled = !isCompanyFields && !["bhi_admin", "company_admin"].includes(profileRole);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };

  const formatType = (type?: string): string => {
    if (type === "string") {
      return "text";
    }
    return type ? type : "text";
  };

  const clearName = (name: string): string => {
    const chunks = name.split(".");
    return chunks[chunks.length - 1];
  };

  const formatTableRows = (defaultField: DefaultCustomField[]) => {
    return defaultField.map(
      ({ name, description, type, metadataId }: DefaultCustomField) => {
        return {
          id: name,
          name: clearName(name),
          description,
          type: formatType(type),
          actions: "edit/delete",
          metadataId: metadataId,
        };
      }
    );
  };

  const refreshCustomFields = () => {
    setEditableField(undefined);
    if (selectedCompanyId) {
      const reqQuery: GetCustomFieldsParams = {
        companyId: selectedCompanyId,
        limit: itemsPerPage,
        offset: page * itemsPerPage,
        sort: `${getSortFieldForApi(sortField)}_${sortDir}`,
        types: isCompanyFields ? 'company' : 'contact',
      };
      if (searchQuery) {
        reqQuery.query = searchQuery;
      }
      getDefaultValues(reqQuery).then((data) => {
        setDefaultFields(data.data);
      });
    }
  };

  const mapSortPropsToApi: Record<string, string> = {
    name: "name",
    description: "description",
    // 'type': 'type',
  };

  const isFieldSortable = (fieldName: string) =>
    Object.prototype.hasOwnProperty.call(mapSortPropsToApi, fieldName);

  const getSortFieldForApi = (fieldName: string) => {
    return mapSortPropsToApi[fieldName];
  };

  const swapSortDirection = (dir?: "asc" | "desc") => {
    if (dir) {
      setSortDir(dir);
    } else {
      setSortDir(sortDir == "asc" ? "desc" : "asc");
    }
  };

  const changeSortField = (fieldName: string) => {
    if (sortField == fieldName) {
      swapSortDirection();
    } else {
      swapSortDirection("desc");
    }
    setSortField(fieldName);
  };

  const handleSearchQuery = (text: string) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    refreshCustomFields();
  }, [page, selectedCompanyId, sortDir, sortField, searchQuery]);

  useEffect(() => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
  }, [sortDir, sortField, searchQuery]); 

  const handleContactFieldEdit = (name: string) => {
    if (defaultFields?.items) {
      const field = defaultFields.items.find((item) => item.name === name);
      if (field) {
        setEditableField({
          id: field.name,
          metadataId: field.metadataId,
          companyId: "",
          type: "string",
          name: clearName(field.name),
          description: field.description,
          defaultValue: field.defaultValue,
        });
        setCreateCustomFieldModal(true);
      }
    }
  };

  const handleCompanyFieldEdit = (name: string) => {
    if (defaultFields.items && selectedCompanyId) {
      const field = defaultFields.items.find((item) => item.name === name);
      if (field) {
        setEditableField({
          id: field.name,
          metadataId: field.metadataId,
          companyId: selectedCompanyId,
          type: "string",
          name: clearName(field.name),
          description: field.description,
          defaultValue: field.defaultValue,
        });
        setCreateCustomFieldModal(true);
      }
    }
  };

  const handleCustomFieldDelete = (id: string) => {
    selectedCompanyId &&
      deleteCustomField(id, selectedCompanyId).then(refreshCustomFields);
  };

  const isStandardField = (name: string) => {
    const prefix = isCompanyFields ? "company.standard" : "contact.standard";
    return name.startsWith(prefix);
  };

  return (
    <>
      <HeaderContainer>
        <SearchInput handleSearch={handleSearchQuery} />
        {showButtons && !isCompanyFields && (
          <Button
            disabled={isButtonsDisabled}
            variant="contained"
            onClick={() => {
              setEditableField(undefined);
              setCreateCustomFieldModal(true);
            }}
          >
            Create Custom Field
          </Button>
        )}
      </HeaderContainer>
      <CreateCustomFieldModal
        isCompanyFields={isCompanyFields}
        show={showCreateCustomFieldModal}
        setShowModal={setCreateCustomFieldModal}
        customFieldData={editableField}
        refreshCustomFields={refreshCustomFields}
      />

      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "20px" }}>
        <TableContainer sx={{ height: "calc(100vh - 300px)" }}>
          <Table ref={tableRef}  stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (column.id === "actions" && !showButtons) {
                    return null;
                  }
                  if (isCompanyFields && column.id === "type") {
                    return null;
                  }
                  return (
                    <TableCell
                      key={column.id}
                      onClick={() =>
                        isFieldSortable(column.id)
                          ? changeSortField(column.id)
                          : {}
                      }
                      align={column.align}
                      width={
                        column.id === "name"
                          ? "10%"
                          : column.id === "actions"
                          ? "20%"
                          : "10%"
                      }
                      style={{
                        background: "#254597",
                        color: "#fff",
                        cursor: isFieldSortable(column.id)
                          ? "pointer"
                          : "initial",
                      }}
                    >
                      {isCompanyFields && column.id === "description"
                        ? "Company Info"
                        : column.label}
                      {sortField == column.id && <SortIcon dir={sortDir} />}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {defaultFields.items &&
                formatTableRows(defaultFields.items).map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {columns.map((column) => {
                        if (column.id === "actions" && !showButtons) {
                          return null;
                        }

                        if (isCompanyFields && column.id === "type") {
                          return null;
                        }

                        const value = row[column.id];
                        const cellWidth =
                          column.id === "name"
                            ? "30%"
                            : column.id === "actions"
                            ? "10%"
                            : "10%";
                        return (
                          <TableCell
                            width={cellWidth}
                            key={column.id}
                            align={column.align}
                          >
                            {column.id === "actions" ? (
                              row.type !== "default" ? (
                                <>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      isCompanyFields
                                        ? handleCompanyFieldEdit(row.id)
                                        : handleContactFieldEdit(row.id);
                                    }}
                                    disabled={row.type === "default" || isButtonsDisabled}
                                  >
                                    <EditIcon />
                                  </Button>
                                  {!isCompanyFields &&
                                    !isStandardField(row.id) && (
                                      <Button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          openDialog({
                                            title: `Delete "${row.name}"`,
                                            content:
                                              "Are you sure you want to delete this custom field?",
                                            onConfirm: () => {
                                              if (row.metadataId) {
                                                handleCustomFieldDelete(row.metadataId);
                                              }
                                            },
                                          });
                                        }}
                                        disabled={row.type === "default" || isButtonsDisabled}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    )}
                                </>
                              ) : null
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={defaultFields.count}
          rowsPerPageOptions={[-1]}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
      <ConfirmationDialog />
    </>
  );
}

export default CustomFields;
