import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

type BannedWordsModalProps = {
  isOpen: boolean;
  onConfirm: React.Dispatch<React.SetStateAction<void>>;
}

const BannedWordsModal = (props: BannedWordsModalProps) => {

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle></DialogTitle>
      <DialogContent>The provided text is unable to be processed as it contains words or phrases that are restricted by carriers.</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => {props.onConfirm()}} color="primary">
          Understood
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default BannedWordsModal;