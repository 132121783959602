import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { PaperPlaneTilt } from "phosphor-react";
import { useTheme, styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachFileUrlModal from "../../../components/AttachFileUrlModal/view";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import ChatFileIcon from "../../../components/ChatFileIcon/view";
import {
  FileLinkWithMimeType,
  InboxContact,
  MessageTemplate,
} from "../../../constants/types";
import toast, { ToastOptions } from "react-hot-toast";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import BannedWordsModal from "src/components/BannedWordsModal/view";

type ChatFooterProps = {
  messageTemplates: MessageTemplate[] | undefined;
  addMessage: (text: string) => Promise<void>;
  updateLinks: (link?: FileLinkWithMimeType) => void;
  isCompanyHasSubscription: boolean;
  selectedContactObject: InboxContact;
};

const StyledInput = styled(TextField)(() => ({
  "& .MuiInputBase-input": {
    border: "none !important",
  },
}));

const Footer = ({
  messageTemplates,
  addMessage,
  updateLinks,
  selectedContactObject,
  isCompanyHasSubscription,
}: ChatFooterProps) => {
  const [message, setMessage] = useState<string>("");
  const [showAttachFileModal, setAttachFileModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [mimeType, setMimeType] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showbannedWordsModal, setShowBannedWordsModal] =
    useState<boolean>(false);
  const [isContactInvalid] = useState<boolean>(
    selectedContactObject.contact.validStatus == "invalid"
  );
  const [isContactOptedOut] = useState<boolean>(
    !!selectedContactObject.contact.optOutDate
  );
  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const messageTemplateIconRef = useRef<HTMLButtonElement>(null);
  const [openEmojiModal, setOpenEmojiModal] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    if (messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (messageInputRef.current) {
      setMessage("");
      setUrl("");
      setMimeType(undefined);
      updateLinks();
    }
  }, [selectedContactObject.contact.id]);

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement | HTMLTextAreaElement>
  ): void => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessage(message + `\n`);
    } else if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      setIsLoading(true);
      addMessage(message)
        .then(() => {
          setMessage("");
          setUrl("");
          setMimeType(undefined);
          updateLinks();
        })
        .catch((e) => {
          if (e.code === 422) {
            setShowBannedWordsModal(true);
          }
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const [openMessageTemplatesList, setOpenMessageTemplatesList] = useState<
    boolean | null
  >(null);
  const handleMessageTemplatesListClose = () => {
    setOpenMessageTemplatesList(false);
  };

  const insertMessageTemplate = (text: string) => {
    const start = messageInputRef.current?.value.substring(
      0,
      messageInputRef.current.selectionStart
    );
    const end = messageInputRef.current?.value.substring(
      messageInputRef.current.selectionStart
    );
    const textWithTemplate = start + text + end;
    setMessage(textWithTemplate);
    window.setTimeout(() => {
      messageInputRef.current?.focus();
      messageInputRef.current?.setSelectionRange(
        messageInputRef.current?.selectionStart + textWithTemplate.length,
        messageInputRef.current?.selectionStart + textWithTemplate.length
      );
    }, 10);
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "transparent !important",
      }}
    >
      <Box
        width={"100%"}
        sx={{
          backgroundColor: "#F8FAFF",
          boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.25)",
        }}
      >
        {url && (
          <Box sx={{ marginLeft: 5, paddingBottom: 1, paddingTop: 1 }}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <ChatFileIcon url={url} mimeType={mimeType} width="10%" />
              <Button
                disabled={!isCompanyHasSubscription}
                onClick={() => {
                  setUrl("");
                  setMimeType(undefined);
                  updateLinks();
                }}
              >
                <DeleteIcon />
              </Button>
            </Stack>
          </Box>
        )}
        <Stack direction="row" justifyContent="start" alignItems={"center"}>
          <IconButton
            disabled={!isCompanyHasSubscription}
            onClick={() => {
              setAttachFileModal(true);
            }}
          >
            <AttachFileIcon />
          </IconButton>
          <>
            <ClickAwayListener
              onClickAway={() => {
                handleMessageTemplatesListClose();
              }}
            >
              <>
                <IconButton
                  disabled={!isCompanyHasSubscription}
                  ref={messageTemplateIconRef}
                  onClick={() => {
                    setOpenMessageTemplatesList(true);
                  }}
                >
                  <TextSnippetOutlinedIcon />
                </IconButton>

                <Menu
                  id="long-menu-message-templates"
                  anchorEl={messageTemplateIconRef.current}
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  open={!!openMessageTemplatesList}
                  onClose={handleMessageTemplatesListClose}
                  slotProps={{
                    paper: {
                      style: {
                        maxHeight: 48 * 4.5,
                      },
                    },
                  }}
                >
                  {messageTemplates &&
                    messageTemplates.length > 0 &&
                    messageTemplates.map((m) => (
                      <MenuItem
                        key={m.name}
                        onClick={() => {
                          insertMessageTemplate(m.text);
                          handleMessageTemplatesListClose();
                        }}
                      >
                        {m.name}
                      </MenuItem>
                    ))}
                  {messageTemplates && messageTemplates.length === 0 && (
                    <MenuItem>No templates available</MenuItem>
                  )}
                </Menu>
              </>
            </ClickAwayListener>
          </>
          <IconButton
            disabled={!isCompanyHasSubscription}
            onClick={() => {
              setOpenEmojiModal(true);
            }}
          >
            <InsertEmoticonIcon />
          </IconButton>
          {openEmojiModal && (
            <ClickAwayListener
              onClickAway={() => {
                setOpenEmojiModal(false);
              }}
            >
              <Box sx={{ position: "absolute", bottom: "60px" }}>
                <EmojiPicker
                  onEmojiClick={(e: EmojiClickData) => {
                    const emoji = e.emoji;
                    const start = messageInputRef.current?.value.substring(
                      0,
                      messageInputRef.current.selectionStart
                    );
                    const end = messageInputRef.current?.value.substring(
                      messageInputRef.current.selectionStart
                    );
                    const text = start + emoji + end;
                    setMessage(text);
                    messageInputRef.current?.focus();
                    messageInputRef.current?.setSelectionRange(
                      messageInputRef.current?.selectionStart,
                      messageInputRef.current?.selectionStart
                    );
                    setOpenEmojiModal(false);
                  }}
                />
              </Box>
            </ClickAwayListener>
          )}
          {showAttachFileModal && (
            <AttachFileUrlModal
              show={showAttachFileModal}
              setShowModal={setAttachFileModal}
              handleFileChange={(link: FileLinkWithMimeType) => {
                setUrl(link.url);
                setMimeType(link.mimeType);
                updateLinks({ url: link.url, mimeType: link.mimeType });
              }}
            />
          )}
          <Stack sx={{ width: "100%", marginLeft: 0 }}>
            <Box
              style={{
                zIndex: 10,
                position: "fixed",
                display: "none",
                bottom: 81,
              }}
            ></Box>
            <StyledInput
              disabled={!isCompanyHasSubscription}
              inputRef={messageInputRef}
              multiline
              value={message}
              size={"small"}
              onKeyDown={onKeyDown}
              onChange={(event) => {
                setMessage(event.target.value);
              }}
              fullWidth
              placeholder="Write a message..."
              variant="outlined"
            />
          </Stack>

          <div
            style={{
              backgroundColor:
                isContactInvalid ||
                isContactOptedOut ||
                !isCompanyHasSubscription
                  ? theme.palette.grey[500]
                  : theme.palette.primary.main,
              borderRadius: "4px",
              marginLeft: "5px",
              marginRight: "5px",
              cursor: "pointer",
            }}
          >
            <IconButton
              disabled={!isCompanyHasSubscription}
              onClick={() => {
                if (isContactInvalid || isContactOptedOut) {
                  const options: ToastOptions = {
                    position: "bottom-right",
                  };
                  if (isContactInvalid) {
                    toast.error(
                      "This user has an invalid phone number and is not able to receive text messages.",
                      options
                    );
                  } else if (isContactOptedOut) {
                    toast.error(
                      "This user has opted out from receiving messages. They must opt back in before you are able to message them.",
                      options
                    );
                  }
                  return;
                }
                setIsLoading(true);
                addMessage(message)
                  .then(() => {
                    setMessage("");
                    setUrl("");
                    setMimeType("");
                    updateLinks();
                  })
                  .catch((e) => {
                    if (e.code === 422) {
                      setShowBannedWordsModal(true);
                    }
                    console.log(e);
                  })
                  .finally(() => {
                    setIsLoading(false);
                  });
              }}
            >
              {isContactInvalid || isContactOptedOut ? (
                <PaperPlaneTilt
                  style={{
                    backgroundColor: isCompanyHasSubscription
                      ? "#264597"
                      : theme.palette.grey[500],
                  }}
                  color="#ffffff"
                />
              ) : isLoading ? (
                <CircularProgress size="24px" color="secondary" />
              ) : (
                <PaperPlaneTilt
                  style={{
                    backgroundColor: isCompanyHasSubscription
                      ? "#264597"
                      : theme.palette.grey[500],
                  }}
                  color="#ffffff"
                />
              )}
            </IconButton>
          </div>
        </Stack>
      </Box>
      <BannedWordsModal
        isOpen={showbannedWordsModal}
        onConfirm={() => setShowBannedWordsModal(false)}
      />
    </Box>
  );
};

export default Footer;
