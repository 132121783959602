import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import {
  AdminCompany,
  Campaign,
  DefaultCustomField,
  MessageTemplate,
} from "src/constants/types";
import CreateAwayMessageCampaignModal from "./CreateAwayMessageCampaignModal/view";
import { useGetDefaultCustomFieldsValuesApi } from "src/api/customFields";
import { useGetMessageTemplatesApi } from "src/api/messageTemplates";
import { selectedCompany } from "src/store/company/companySlice";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import CircleIcon from "@mui/icons-material/Circle";
import {
  useActivateCampaignApi,
  useDeactivateCampaignApi,
  useGetCampaignsApi,
} from "src/api/campaigns";
import { GetMessageTemplatesParams } from "src/api/messageTemplates/types";
import { GetCampaignsParams } from "src/api/campaigns/types";
import { useGetAdminCompanyApi } from "src/api/adminCompanies";

const AwayMessageSwitcher = () => {
  const selectedCompanyId = useSelector(selectedCompany);
  const getCampaigns = useGetCampaignsApi();
  const getCompany = useGetAdminCompanyApi();

  const [awayMessageCampaign, setAwayMessageCampaign] = useState<
    Campaign | undefined
  >(undefined);

  const [company, setCompany] = useState<AdminCompany>();

  const [awayMessageCampaignState, setAwayMessageCampaignState] =
    useState<string>(
      awayMessageCampaign && awayMessageCampaign.status === "active"
        ? "away"
        : "available"
    );
  const [showCreateAwayMessageCampaignModal, setShowCreateAwayMessageCampaign] =
    useState(false);

  const [metadata, setMetadata] = useState<DefaultCustomField[]>();
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getMetadata = useGetDefaultCustomFieldsValuesApi();
  const activate = useActivateCampaignApi();
  const deactivate = useDeactivateCampaignApi();
  const getMessageTemplates = useGetMessageTemplatesApi();

  useEffect(() => {
    setAwayMessageCampaignState(
      awayMessageCampaign && awayMessageCampaign.status === "active"
        ? "away"
        : "available"
    );
  }, [awayMessageCampaign]);

  const getAwayMessageCampaign = () => {
    if (!selectedCompanyId) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: any[] = [];

    const campaignsQuery: GetCampaignsParams = {
      companyId: selectedCompanyId,
      limit: 1,
      offset: 0,
      type: "away_message",
    };
    promises.push(
      getCampaigns(campaignsQuery).then(({ data }) => {
        if (data.items.length) {
          setAwayMessageCampaign(data.items[0]);
        }
      })
    );
    promises.push(
      getCompany(selectedCompanyId).then((response) => {
        setCompany(response.data);
      })
    );

    Promise.all(promises).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getAwayMessageCampaign();
  }, [selectedCompanyId]);

  const handleStatusChange = (event: SelectChangeEvent) => {
    const status = event.target.value;
    setAwayMessageCampaignState(status);
    if (status === "away") {
      if (awayMessageCampaign && selectedCompanyId) {
        activate([awayMessageCampaign.id], selectedCompanyId).then(() => {
          setAwayMessageCampaignState("away");
        });
      } else {
        setShowCreateAwayMessageCampaign(true);
      }
    }

    if (status === "available") {
      if (awayMessageCampaign && selectedCompanyId) {
        deactivate([awayMessageCampaign.id], selectedCompanyId).then(() => {
          setAwayMessageCampaignState("available");
        });
      }
    }
  };

  useEffect(() => {
    if (selectedCompanyId) {
      const params = {
        companyId: selectedCompanyId,
        limit: 10000,
        offset: 0,
        types: "contact,company",
      };
      getMetadata(params)
        .then((data) => {
          setMetadata(data.data.items);
        })
        .catch((e) => {
          console.log(e);
        });
      const messageTemplatesParams: GetMessageTemplatesParams = {
        companyId: selectedCompanyId,
        offset: 0,
        limit: 10000,
        status: "active",
      };
      getMessageTemplates(messageTemplatesParams)
        .then((data) => {
          setMessageTemplates(
            data.data.items.filter((t) => t.companyId === selectedCompanyId)
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedCompanyId]);

  if (isLoading) {
    return (
      <Stack direction="row">
        <CircularProgress size="small" />
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        direction: "row",
        width: "100%",
        justifyContent: "flex-around",
        alignItems: "center",
        marginTop: 0,
      }}
    >
      <Box sx={{ display: "flex", flex: 10, alignItems: "center" }}>
        <Typography sx={{ marginRight: "5px", marginTop: "5px" }}>
          Status:{" "}
        </Typography>
        <FormControl variant="standard" sx={{ minWidth: "70%" }}>
          <Select
            disabled={!company || !company.subscription}
            labelId="select-status-label"
            id="status-standard"
            value={awayMessageCampaignState}
            onChange={handleStatusChange}
            label="Status"
            sx={{
              "& .MuiInputBase-input": {
                paddingBottom: 0,
              },
            }}
          >
            <MenuItem value={"available"}>
              <Stack direction="row" alignItems="center">
                <CircleIcon
                  sx={{
                    scale: "0.7",
                    marginRight: "2px",
                    marginTop: "-2px",
                  }}
                  htmlColor={company && company.subscription ? "green" : "grey"}
                  fontSize="small"
                />
                {company && company.subscription
                  ? "Available"
                  : "No Active Plan"}
              </Stack>
            </MenuItem>
            <MenuItem value={"away"}>
              <Stack direction="row" alignItems="center">
                <CircleIcon
                  sx={{ scale: "0.7", marginRight: "2px", marginTop: "-2px" }}
                  fontSize="small"
                  htmlColor={company && company.subscription ? "red" : "grey"}
                />
                {company && company.subscription ? "Away" : "No Active Plan"}
              </Stack>
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <EditIcon
        sx={{
          flex: 1,
          cursor: company && company.subscription ? "pointer" : "initial",
          color: "grey",
        }}
        onClick={() => {
          if (company && company.subscription) {
            setShowCreateAwayMessageCampaign(true);
          }
        }}
      />
      <CreateAwayMessageCampaignModal
        refreshAwayMessage={getAwayMessageCampaign}
        show={showCreateAwayMessageCampaignModal}
        setShowModal={setShowCreateAwayMessageCampaign}
        awayMessageCampaign={awayMessageCampaign}
        metadata={metadata}
        messageTemplates={messageTemplates}
      />
    </Box>
  );
};

export default AwayMessageSwitcher;
