import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { InboxContact, User } from "src/constants/types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

const StyledChatBox = styled(Box)(() => ({
  "&:hover": {
    cursor: "pointer",
  },
}));

type ContactProps = {
  contact: InboxContact;
  selectedContact: string | null;
  onSelectChat: (contactId: string) => void;
  lastSelectedChatMessageDate: string | undefined;
  users: User[];
};

const Contact = ({
  contact,
  selectedContact,
  onSelectChat,
  users,
}: ContactProps) => {
  dayjs.extend(relativeTime);
  const isSelected = contact.contact.id === selectedContact;
  const theme = useTheme();
  const assignedUser =
    users &&
    contact.contact.assignedTo &&
    users.find((u) => u.id === contact.contact.assignedTo);

  const formatName = (contact: User): string => {
    if (contact.firstName || contact.lastName) {
      return `${contact.firstName || ""} ${contact.lastName || ""}`;
    }

    return contact.phoneNumber;
  };
  return (
    <StyledChatBox
      onClick={() => {
        onSelectChat(contact.contact.id);
      }}
      sx={{
        cursor: "pointer",
        backgroundColor: isSelected
          ? alpha(theme.palette.primary.main, 0.5)
          : theme.palette.background.default,
        color: isSelected ? "#fff" : "#000",
      }}
      p={2}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        paddingRight={0}
        justifyContent={"space-between"}
      >
        <Box>
          <Stack direction="row" alignItems="center" sx={{ paddingRight: 0 }}>
            {!contact.isChatRead && (
              <Box sx={{ width: 15, height: 15, marginRight: 1 }}>
                <CircleRoundedIcon
                  sx={{ width: 15, height: 15 }}
                  color="success"
                />
              </Box>
            )}
            <Stack direction="column" spacing={0.3}>
              <Typography sx={{ fontWeight: 600 }} variant="subtitle2">
                {`${contact.contact.firstName || ""} ${
                  contact.contact.lastName || ""
                }`}
              </Typography>
              <Typography variant="caption">
                {contact.contact.phoneNumber}
              </Typography>
              {assignedUser && (
                <Typography sx={{ fontStyle: 'italic' }} variant="caption">Assigned To: {formatName(assignedUser)}</Typography>
              )}

              {contact.lastMessageDate && (
                <Typography
                  sx={{ color: "#ccc", fontSize: 10 }}
                  variant="caption"
                >
                  {dayjs(contact.lastMessageDate).format("YYYY-MM-DD hh:mm A")}
                </Typography>
              )}
            </Stack>
            <Box></Box>
          </Stack>
        </Box>
      </Stack>
    </StyledChatBox>
  );
};

export default Contact;
