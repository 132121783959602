import React, { useEffect, useContext } from "react";
import router from "src/routes/Routes";
import { useGetCompaniesService } from "src/services/company";
import {
  useGetPermissionsService,
  useGetProfileService,
  useGetRolesService,
} from "src/services/profile";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import { AuthContext } from "src/contexts/AuthContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { UpdateNotification } from "react-update-popup";
import "react-update-popup/dist/index.css";
import "./index.css"

import { RouterProvider } from "react-router-dom";




const theme = createTheme({
  palette: {
    primary: {
      main: "#254597",
    },
    secondary: {
      main: "#4499D2",
    },
  },
});


function App() {
  const getCompanies = useGetCompaniesService();
  const getPermissions = useGetPermissionsService();
  const getRoles = useGetRolesService();
  const getProfile = useGetProfileService();
  const selectedCompanyId = useSelector(selectedCompany);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    !!authContext?.isAuthenticated && getCompanies();
  }, [authContext?.isAuthenticated]);

  useEffect(() => {
    selectedCompanyId && getPermissions(selectedCompanyId);
    selectedCompanyId && getProfile(selectedCompanyId);
    selectedCompanyId && getRoles();
  }, [selectedCompanyId]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <UpdateNotification />
    </ThemeProvider>
  );
}

export default App;
