import React, { Fragment, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { ContentContainer } from "./styles";
import Button from "@mui/material/Button";
import {
  customFieldValidationNameSchema,
  customFieldValidationDescriptionSchema,
  ValidationErrors,
} from "src/constants/validations";
import { CustomField } from "src/constants/types";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { selectedCompany } from "src/store/company/companySlice";
import {
  useCreateCustomFieldApi,
  useGetDefaultCustomFieldsValuesApi,
  useUpdateCustomFieldApi,
  useUpdateDefaultCustomFieldsValues,
} from "src/api/customFields";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
  Tooltip,
  Stack,
} from "@mui/material";
import { firstLetterCamelCase } from "../../../../../constants/helpers";
import HelpIcon from "@mui/icons-material/Help";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import BannedWordsModal from "src/components/BannedWordsModal/view";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "700px",
    height: "700px",
  },
}));

type CreateCustomFieldModalType = {
  show: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  customFieldData?: CustomField;
  refreshCustomFields: () => void;
  isCompanyFields: boolean;
};
const initialValue = {
  name: "",
  description: "",
  type: "string",
  defaultValue: "",
};

export default function CreateKeywordModal({
  show,
  setShowModal,
  customFieldData,
  refreshCustomFields,
  isCompanyFields,
}: CreateCustomFieldModalType) {
  const isContactStandardField = (name: string | undefined) => {
    if (!name) {
      return false;
    }
    return name.startsWith("contact.standard");
  };

  const isStandardField = (id: string | undefined) => {
    if (!id) {
      return false;
    }
    return id.split(".")[1] === "standard";
  };

  const customFieldPrefix = isCompanyFields
    ? "company.standard."
    : isContactStandardField(
        customFieldData?.metadataId
          ? customFieldData?.name
          : customFieldData?.id
      )
    ? "contact.standard."
    : "contact.custom.";

  const [formValues, setFormValues] = useState<{
    name: string;
    description: string;
    type: string;
    defaultValue: string | null;
  }>(initialValue);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [showbannedWordsModal, setShowBannedWordsModal] =
    useState<boolean>(false);
  const createCustomField = useCreateCustomFieldApi();
  const updateContactCustomField = useUpdateCustomFieldApi();
  const getDefaultValues = useGetDefaultCustomFieldsValuesApi();
  const updateDefaultValue = useUpdateDefaultCustomFieldsValues();
  const selectedCompanyId = useSelector(selectedCompany);

  const handleClose = () => {
    setShowModal(false);
    setFormValues(initialValue);
    setErrors({});
  };

  const handleSelectChange = ({ target }: SelectChangeEvent<string>) => {
    setFormValues({ ...formValues, type: target.value, defaultValue: "" });
  };

  const handleCustomFieldSave = () => {
    const isValid = isContactStandardField(customFieldData?.name)
      ? true
      : validate(formValues);
    if (isValid) {
      const defaultValue = formValues.defaultValue?.trim();
      if (customFieldData?.metadataId && selectedCompanyId) {
        !isCompanyFields &&
          !isContactStandardField(customFieldData.name) &&
          updateContactCustomField(
            {
              name: formValues.name,
              description: formValues.description,
              defaultValue: defaultValue || null,
            },
            customFieldData?.metadataId,
            selectedCompanyId
          )
            .then(() => {
              updateDefaultValue(
                [
                  {
                    name: `${customFieldPrefix}${formValues.name}`,
                    value: defaultValue || null,
                  },
                ],
                selectedCompanyId
              )
                .then(() => {
                  handleClose();
                  refreshCustomFields();
                })
                .catch((error) => {
                  if (error.code === 422) {
                    setShowBannedWordsModal(true);
                  }
                  console.log(error);
                });
            })
            .catch((e) => {
              console.log(e);
              if (e.code === 422) {
                setShowBannedWordsModal(true);
              }
              if (e.error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
              if (e.error.message && e.error.message.includes("already exists")) {
                setErrors({
                  name: "Entity with this name already exists",
                });
              }
            })
            .catch((error) => {
              if (error.code === 422) {
                setShowBannedWordsModal(true);
              }
              console.log(error);
            });

        (isCompanyFields || isContactStandardField(customFieldData.name)) &&
          updateDefaultValue(
            [
              {
                name: `${customFieldPrefix}${formValues.name}`,
                value: defaultValue || null,
              },
            ],
            selectedCompanyId
          )
            .then(() => {
              handleClose();
              refreshCustomFields();
            })
            .catch(({ error }) => {
              if (error === "conflict") {
                setErrors({ name: "Entity with this name already exists" });
              }
              if (error.message && error.message.includes("already exists")) {
                setErrors({
                  name: "Entity with this name already exists",
                });
              }
            })
            .catch((error) => {
              if (error.code === 422) {
                setShowBannedWordsModal(true);
              }
              console.log(error);
            });
      } else {
        if (selectedCompanyId) {
          if (
            customFieldData &&
            isContactStandardField(
              customFieldData.metadataId
                ? customFieldData?.name
                : customFieldData?.id
            )
          ) {
            updateDefaultValue(
              [
                {
                  name: `${customFieldPrefix}${formValues.name}`,
                  value: defaultValue || null,
                },
              ],
              selectedCompanyId
            )
              .then(() => {
                handleClose();
                refreshCustomFields();
              })
              .catch(({ error }) => {
                console.log(error);
              });
          } else {
            createCustomField(
              {
                name: formValues.name,
                description: formValues.description,
                type: formValues.type,
                defaultValue: defaultValue || null,
              },
              selectedCompanyId
            )
              .then(() => {
                updateDefaultValue(
                  [
                    {
                      name: `${customFieldPrefix}${formValues.name}`,
                      value: defaultValue || null,
                    },
                  ],
                  selectedCompanyId
                )
                  .then(() => {
                    handleClose();
                    refreshCustomFields();
                  })
                  .catch((error) => {
                    if (error.code === 422) {
                      setShowBannedWordsModal(true);
                    }
                    console.log(error);
                  });
              })
              .catch((e) => {
                if (e.code === 422) {
                  setShowBannedWordsModal(true);
                }
                if (e.error === "conflict") {
                  setErrors({ name: "Entity with this name already exists" });
                }
                if (
                  e.error.message &&
                  e.error.message.includes("already exists")
                ) {
                  setErrors({
                    name: "Entity with this name already exists",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      }
    }
  };

  useEffect(() => {
    selectedCompanyId &&
      customFieldData &&
      getDefaultValues({
        companyId: selectedCompanyId,
        offset: 0,
        limit: 100000,
        types: "contact,company",
      }).then((data) => {
        const customField = data.data.items.find(
          (f) => f.name === `${customFieldPrefix}${customFieldData?.name}`
        );
        if (customField) {
          customFieldData.defaultValue = customField.defaultValue;
          customFieldData.type = customField.type || "string";
        }
        setFormValues(customFieldData);
      });

    return () => {
      setFormValues({
        name: "",
        description: "",
        type: "string",
        defaultValue: "",
      });
    };
  }, [customFieldData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newValues = { ...formValues, [name]: value };
    if (value.length > 1) {
      validate(newValues, false);
    }
    setFormValues(newValues);
  };

  const isValidForm = (values: typeof formValues) => {
    if (isContactStandardField(customFieldData?.name)) {
      return true;
    }

    try {
      customFieldValidationNameSchema.validateSync(values, {
        abortEarly: false,
      });
      if (!isCompanyFields) {
        customFieldValidationDescriptionSchema.validateSync(values, {
          abortEarly: false,
        });
      }

      return true;
    } catch {
      return false;
    }
  };

  const validate = (values: typeof formValues, checkDescription = true) => {
    try {
      if (checkDescription && !isCompanyFields) {
        customFieldValidationDescriptionSchema.validateSync(values, {
          abortEarly: false,
        });
      }
      customFieldValidationNameSchema.validateSync(values, {
        abortEarly: false,
      });

      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const newErrors = err.inner?.reduce((acc, curr) => {
          if (curr.path) {
            acc[curr.path] = curr?.message;
          }
          return acc;
        }, {} as ValidationErrors);
        setErrors(newErrors);
      }
      return false;
    }
  };

  const getDefaultValueInputType = (type: string) => {
    switch (type) {
      case "number":
        return "number";
      case "string":
        return "string";
      case "date":
        return "date";
      default:
        "string";
    }
  };

  return (
    <Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {customFieldData ? "Update " : "Create "}{" "}
          {isCompanyFields
            ? `${customFieldData?.description || ""}`
            : "Contact Field"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ContentContainer>
            {!isCompanyFields && (
              <TextField
                id="outlined-basic"
                label={isCompanyFields ? "Company Info" : "Display Name"}
                name="description"
                disabled={
                  isCompanyFields || isStandardField(customFieldData?.id)
                }
                variant="outlined"
                value={formValues.description}
                onChange={handleChange}
                error={!!errors.description}
                helperText={errors?.description || ""}
              />
            )}
            {!isCompanyFields && (
              <TextField
                sx={{ marginTop: "20px" }}
                disabled={
                  isCompanyFields || isStandardField(customFieldData?.id)
                }
                id="outlined-basic"
                label="Merge Tag"
                name="name"
                variant="outlined"
                value={formValues.name}
                onChange={handleChangeName}
                error={!!errors.name}
                helperText={errors?.name || ""}
              />
            )}
            {!customFieldData?.id && !isCompanyFields && (
              <FormControl sx={{ marginTop: "20px" }}>
                <InputLabel htmlFor="bhi-simple-select">Type</InputLabel>
                <Select
                  labelId="bhi-simple-select-label"
                  id="bhi-simple-select"
                  value={formValues.type}
                  label="Type"
                  onChange={handleSelectChange}
                  sx={{ minWidth: "150px" }}
                >
                  <MenuItem value={"string"} key={"string"}>
                    Text
                  </MenuItem>
                  <MenuItem value={"number"} key={"number"}>
                    Number
                  </MenuItem>
                  <MenuItem value={"date"} key={"date"}>
                    Date
                  </MenuItem>
                </Select>
              </FormControl>
            )}

            {customFieldData?.id && !isCompanyFields && (
              <TextField
                sx={{ marginTop: "20px" }}
                id="outlined-basic"
                label="type"
                disabled
                variant="outlined"
                value={firstLetterCamelCase(formValues.type)}
              />
            )}
            {formValues.type === "date" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker"]}
                  sx={{ marginTop: isCompanyFields ? "-10px" : "10px" }}
                >
                  <DatePicker
                    slotProps={{ textField: { error: false } }}
                    sx={{ width: "100%" }}
                    className={!formValues.defaultValue ? "empty-date" : ""}
                    name="defaultValue"
                    format="YYYY-MM-DD"
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    closeOnSelect={true}
                    onChange={(value: Dayjs | undefined) =>
                      setFormValues({
                        ...formValues,
                        defaultValue: value ? value.format("YYYY-MM-DD") : "",
                      })
                    }
                    label={
                      isCompanyFields
                        ? customFieldData?.description
                        : "Default Value"
                    }
                    value={
                      formValues.defaultValue === "null"
                        ? ""
                        : dayjs(formValues.defaultValue)
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
            {formValues.type !== "date" && (
              <TextField
                type={getDefaultValueInputType(formValues.type)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{ display: isCompanyFields ? "none;" : "flex" }}
                      hidden={isCompanyFields}
                      position="end"
                    >
                      <Tooltip
                        placement="top"
                        title={`When a default value is entered, this will be the value used for the merge tag when no ${
                          isCompanyFields ? "company" : "contact specific"
                        } metadata is provided.`}
                        arrow
                      >
                        <HelpIcon
                          fontSize="small"
                          sx={{ marginLeft: "5px", cursor: "pointer" }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: isCompanyFields ? "-10px" : "20px" }}
                id="outlined-basic"
                label={
                  isCompanyFields
                    ? customFieldData?.description
                    : "Default Value"
                }
                name="defaultValue"
                variant="outlined"
                value={
                  formValues.defaultValue === "null"
                    ? ""
                    : formValues.defaultValue
                }
                onChange={handleChange}
                error={!!errors.defaultValue}
                helperText={errors?.defaultValue || ""}
              />
            )}
            <Stack direction="row" width="100%" justifyContent="end">
              <Button
                disabled={!isValidForm(formValues)}
                variant="contained"
                style={{ width: "150px", marginTop: "20px" }}
                onClick={handleCustomFieldSave}
              >
                Save
              </Button>
            </Stack>
          </ContentContainer>
        </DialogContent>
      </BootstrapDialog>
      <BannedWordsModal
        isOpen={showbannedWordsModal}
        onConfirm={() => setShowBannedWordsModal(false)}
      />
    </Fragment>
  );
}
