import * as Yup from 'yup';

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const customFieldNameRegex = /^[a-zA-Z0-9_-]+$/;

const phonenumberRegex = /^\d{10}$/;

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, 'Invalid email format.').required('Email is required.'),
    password: Yup.string().required('Password is required.'),
});

export const emailValidationSchema = Yup.object().shape({ email: Yup.string().matches(emailRegex, 'Invalid email format.').required('Email is required.') });

export const passwordValidationSchema = Yup.object().shape({ password: Yup.string().min(8, 'Password must be at least 8 characters long.').required('Password is required.') });

export type ValidationErrors = {
    [key: string]: string;
}

export const groupValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
});

export const keywordValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
});

export const customFieldValidationDescriptionSchema = Yup.object().shape({
    description: Yup.string().required('Description is required.').max(32),
    type: Yup.string().required('Type is required'),
});

export const customFieldValidationNameSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.').matches(customFieldNameRegex, 'Invalid name format. Only upper/lowercase letters (A-z), digits (0-9), underscore (_), dash (-) are allowed'),
});

export const createContactValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Phone Number is required.').matches(phonenumberRegex, 'Invalid phone number.'),
    email: Yup.string().notRequired().when('email', {
        is: (value: string) => value?.length,
        then: (rule) => rule.matches(emailRegex, 'Invalid email format.'),
    }),
}, [
    ['email', 'email'],
]);

export const messageCampaignValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    groupIds: Yup.array().min(1, 'Groups are required.'),
});

export const messageValidationSchema = Yup.object().shape({
    text: Yup.string().required('Message is required.'),
});

export const joinCampaignValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    groupIds: Yup.array().min(1, 'Groups are required.'),
});

export const textToJoinCampaignValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    keywordIds:Yup.array().min(1, 'Keywords are required.'),
    groupIds: Yup.array().min(1, 'Groups are required.'),
});

export const appointmentReminderCampaignValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    appointmentTypeId: Yup.string().required('Appointment Type is required.'),
});

export const birthdayCampaignValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
});

export const annualCampaignValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    metadataName: Yup.string().required('Contact Date Time is required.')
});

export const updateUserValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string().notRequired().when('phoneNumber', {
        is: (value: string) => value?.length,
        then: (rule) => rule.matches(phonenumberRegex, 'Invalid phone number.'),
    }),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
}, [
    ['phoneNumber', 'phoneNumber'],
])


export const inviteUserValidationSchema = Yup.object().shape({
    phoneNumber: Yup.string().notRequired().when('phoneNumber', {
        is: (value: string) => value?.length,
        then: (rule) => rule.matches(phonenumberRegex, 'Invalid phone number.'),
    }),
    email: Yup.string().matches(emailRegex, 'Invalid email format.').required('Email is required.'),
    firstName: Yup.string().required('First Name is required.'),
    lastName: Yup.string().required('Last Name is required.'),
    roleId: Yup.string().required('Role is required.'),
}, [
    ['phoneNumber', 'phoneNumber'],
]);

export const addIntegrationValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    url: Yup.string().required('Url required.').url('Invalid url format.'),
}, [
    ['name', 'url'],
]);

export const addApikeyValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    permissions: Yup.array().min(1, 'Permissions are required.'),
});

export const addAdminCompanyValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    application: Yup.object({
        id: Yup.string().required('Application is required.'),
    }),
    createMessageProfile: Yup.boolean(),
});

export const editAdminCompanyValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    application: Yup.object({
        id: Yup.string().required('Application is required.'),
    }),
    messagingProfileId: Yup.string().required('Messaging Profile Id is requred.'),
});

export const addMessageTemplateValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    text: Yup.string().required('Template text is required.'),
});

export const addAwayCampaignValidationSchema = Yup.object().shape({
    text: Yup.string().required('Text is required.'),
});

export const addAppointmentTypeValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    color: Yup.string().required('Color text is required.'),
});

export const addAppointmentValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    appointmentType: Yup.object().required('Appointment type is required.'),
    startDate: Yup.string().required('Start date is required.'),
    endDate: Yup.string().required('End date is required.'),
});