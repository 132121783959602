import styled from 'styled-components';

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-right: 80px;
    margin-top: 20px;
    margin-bottom: 20px;
`
export const Container = styled.div`
    display: flex;
`

export const PhoneContainer = styled.div`
    position: fixed;
    right: 2.5%;
    top: 127px;
`